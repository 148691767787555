body {
    background: #fafafa;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}



/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
/*
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}
*/

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 45px;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.crm2print-logo-large {
    display: inline-block;
    width: 303px;
    height: 303px;
    background: url("../images/logos/logo-blue-on-white.png") no-repeat center top;
    background-size: contain;
}

.crm2print-logo {
    display: inline-block;
    background: url('../images/logos/crm2print.svg') no-repeat;
    width: 228px;
    height: 234px;
}

@keyframes sendLetter {
    0% {
        /*si-doc*/
        content: "\e085";
    }
    10% {
        /*si-printer*/
        content: "\e02e";
    }
    20% {
        /*si-docs*/
        content: "\e040";
    }
    30% {
        /*si-envelope-open*/
        content: "\e01e";
    }
    40% {
        /*si-envelope-letter*/
        content: "\e01f";
    }
    50% {
        /*si-envelope*/
        content: "\e086";
    }
    60% {
        /*si-paper-plane*/
        content: "\e094";
    }
    70% {
        /*si-like*/
        content: "\e068";
    }
}

.si-animated-envelope::after {
    content: "\e085";
    color:#3c8eb5;
    /* name duration timing-function delay iteration-count direction fill-mode play-state;*/
    animation: sendLetter 10s linear 2s infinite normal;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .crm2print-logo-large {
        /*background: url("../images/hipster2x.png") no-repeat center top;*/
        background: url("../images/logos/logo-blue-on-white.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td verifically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}

.ui-select-highlight {
    font-weight: bold;
}



.credit-card .ng-invalid {
    border-color: #f00 !important;
}
/* ====== hopscotch fixes due to css concat forgetting images ==*/
div.hopscotch-bubble .hopscotch-bubble-close,
div.hopscotch-bubble .hopscotch-bubble-number {
    background-image:  url(../images/hopscotch-sprite-green.png);
}

/* jhipster-needle-css-add-main JHipster will add new css style */

.swal2-popup .swal2-content {
    font-size: 1.5em;
}


/* admin dash board */
@media screen and (min-width: 1001px) {
    .job-management-icon {
        font-size: 2vw;
    }
    .metric-number-lg {
        font-size: 2vw;
    }
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
    .job-management-icon {
        font-size: 2vw;
    }
    .metric-number-lg {
        font-size: 4vw;
    }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
    .job-management-icon, .metric-number-lg {
        font-size: 6vw;
    }
    .metric-number-lg {
        font-size: 7vw;
    }
}
;
/*!
* OneUI - v2.0.0 - Auto-compiled on 2016-03-15 - Copyright 2016
* @author pixelcave
*/
html,
body {
  height: 100%;
}
body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #646464;
  background-color: #f5f5f5;
}
.no-focus *:focus {
  outline: 0 !important;
}
a {
  color: #5c90d2;
  -webkit-transition: color 0.12s ease-out;
  transition: color 0.12s ease-out;
}
a.link-effect {
  position: relative;
}
a.link-effect:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #3169b1;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.12s ease-out;
  transition: transform 0.12s ease-out;
}
a:hover,
a:focus {
  color: #3169b1;
  text-decoration: none;
}
a:hover.link-effect:before,
a:focus.link-effect:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
a:active {
  color: #5c90d2;
}
a.inactive {
  cursor: not-allowed;
}
a.inactive:focus {
  background-color: transparent !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: 600;
  font-size: 85%;
  color: #777;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: inherit;
}
h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 20px;
}
h5,
.h5 {
  font-size: 16px;
}
h6,
.h6 {
  font-size: 14px;
}
.page-heading {
  color: #545454;
  font-size: 28px;
  font-weight: 400;
}
.page-heading small {
  margin-top: 5px;
  display: block;
  color: #777;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}
@media screen and (min-width: 768px) {
  .page-heading small {
    margin-top: 0;
    display: inline;
    line-height: inherit;
  }
}
.content-heading {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 15px;
  color: #8c8c8c;
  text-transform: uppercase;
}
.content-heading small {
  font-size: 13px;
  font-weight: normal;
  color: #999999;
  text-transform: none;
}
@media screen and (min-width: 768px) {
  .content-heading {
    margin-top: 25px;
  }
}
.font-w300 {
  font-weight: 300 !important;
}
.font-w400 {
  font-weight: 400 !important;
}
.font-w600 {
  font-weight: 600 !important;
}
.font-w700 {
  font-weight: 700 !important;
}
.font-s12 {
  font-size: 12px !important;
}
.font-s13 {
  font-size: 13px !important;
}
.font-s36 {
  font-size: 36px !important;
}
.font-s48 {
  font-size: 48px !important;
}
.font-s64 {
  font-size: 64px !important;
}
.font-s96 {
  font-size: 96px !important;
}
.font-s128 {
  font-size: 128px !important;
}
p {
  line-height: 1.6;
}
p.nice-copy,
.nice-copy p {
  line-height: 1.8;
}
p.nice-copy-story,
.nice-copy-story p {
  line-height: 1.8;
  font-size: 16px;
}
.text-muted {
  color: #999999;
}
a.text-muted:hover,
a.text-muted:active,
a.text-muted:focus,
button.text-muted:hover,
button.text-muted:active,
button.text-muted:focus {
  color: #999999;
  opacity: .75;
}
.text-primary {
  color: #5c90d2;
}
a.text-primary:hover,
a.text-primary:active,
a.text-primary:focus,
button.text-primary:hover,
button.text-primary:active,
button.text-primary:focus {
  color: #5c90d2;
  opacity: .75;
}
.text-primary-dark {
  color: #3e4a59;
}
a.text-primary-dark:hover,
a.text-primary-dark:active,
a.text-primary-dark:focus,
button.text-primary-dark:hover,
button.text-primary-dark:active,
button.text-primary-dark:focus {
  color: #3e4a59;
  opacity: .75;
}
.text-primary-darker {
  color: #2c343f;
}
a.text-primary-darker:hover,
a.text-primary-darker:active,
a.text-primary-darker:focus,
button.text-primary-darker:hover,
button.text-primary-darker:active,
button.text-primary-darker:focus {
  color: #2c343f;
  opacity: .75;
}
.text-primary-light {
  color: #98b9e3;
}
a.text-primary-light:hover,
a.text-primary-light:active,
a.text-primary-light:focus,
button.text-primary-light:hover,
button.text-primary-light:active,
button.text-primary-light:focus {
  color: #98b9e3;
  opacity: .75;
}
.text-primary-lighter {
  color: #ccdcf1;
}
a.text-primary-lighter:hover,
a.text-primary-lighter:active,
a.text-primary-lighter:focus,
button.text-primary-lighter:hover,
button.text-primary-lighter:active,
button.text-primary-lighter:focus {
  color: #ccdcf1;
  opacity: .75;
}
.text-success {
  color: #46c37b;
}
a.text-success:hover,
a.text-success:active,
a.text-success:focus,
button.text-success:hover,
button.text-success:active,
button.text-success:focus {
  color: #46c37b;
  opacity: .75;
}
.text-warning {
  color: #f3b760;
}
a.text-warning:hover,
a.text-warning:active,
a.text-warning:focus,
button.text-warning:hover,
button.text-warning:active,
button.text-warning:focus {
  color: #f3b760;
  opacity: .75;
}
.text-info {
  color: #70b9eb;
}
a.text-info:hover,
a.text-info:active,
a.text-info:focus,
button.text-info:hover,
button.text-info:active,
button.text-info:focus {
  color: #70b9eb;
  opacity: .75;
}
.text-danger {
  color: #d26a5c;
}
a.text-danger:hover,
a.text-danger:active,
a.text-danger:focus,
button.text-danger:hover,
button.text-danger:active,
button.text-danger:focus {
  color: #d26a5c;
  opacity: .75;
}
.text-success-light {
  color: #e0f5e9;
}
a.text-success-light:hover,
a.text-success-light:active,
a.text-success-light:focus,
button.text-success-light:hover,
button.text-success-light:active,
button.text-success-light:focus {
  color: #e0f5e9;
  opacity: .75;
}
.text-warning-light {
  color: #fdf3e5;
}
a.text-warning-light:hover,
a.text-warning-light:active,
a.text-warning-light:focus,
button.text-warning-light:hover,
button.text-warning-light:active,
button.text-warning-light:focus {
  color: #fdf3e5;
  opacity: .75;
}
.text-info-light {
  color: #edf6fd;
}
a.text-info-light:hover,
a.text-info-light:active,
a.text-info-light:focus,
button.text-info-light:hover,
button.text-info-light:active,
button.text-info-light:focus {
  color: #edf6fd;
  opacity: .75;
}
.text-danger-light {
  color: #f9eae8;
}
a.text-danger-light:hover,
a.text-danger-light:active,
a.text-danger-light:focus,
button.text-danger-light:hover,
button.text-danger-light:active,
button.text-danger-light:focus {
  color: #f9eae8;
  opacity: .75;
}
.text-white {
  color: #fff;
}
a.text-white:hover,
a.text-white:active,
a.text-white:focus,
button.text-white:hover,
button.text-white:active,
button.text-white:focus {
  color: #fff;
  opacity: .75;
}
.text-white-op {
  color: rgba(255, 255, 255, 0.85);
}
a.text-white-op:hover,
a.text-white-op:active,
a.text-white-op:focus,
button.text-white-op:hover,
button.text-white-op:active,
button.text-white-op:focus {
  color: rgba(255, 255, 255, 0.85);
  opacity: .75;
}
.text-black {
  color: #000;
}
a.text-black:hover,
a.text-black:active,
a.text-black:focus,
button.text-black:hover,
button.text-black:active,
button.text-black:focus {
  color: #000;
  opacity: .75;
}
.text-black-op {
  color: rgba(0, 0, 0, 0.5);
}
a.text-black-op:hover,
a.text-black-op:active,
a.text-black-op:focus,
button.text-black-op:hover,
button.text-black-op:active,
button.text-black-op:focus {
  color: rgba(0, 0, 0, 0.5);
  opacity: .75;
}
.text-gray {
  color: #c9c9c9;
}
a.text-gray:hover,
a.text-gray:active,
a.text-gray:focus,
button.text-gray:hover,
button.text-gray:active,
button.text-gray:focus {
  color: #c9c9c9;
  opacity: .75;
}
.text-gray-dark {
  color: #999999;
}
a.text-gray-dark:hover,
a.text-gray-dark:active,
a.text-gray-dark:focus,
button.text-gray-dark:hover,
button.text-gray-dark:active,
button.text-gray-dark:focus {
  color: #999999;
  opacity: .75;
}
.text-gray-darker {
  color: #393939;
}
a.text-gray-darker:hover,
a.text-gray-darker:active,
a.text-gray-darker:focus,
button.text-gray-darker:hover,
button.text-gray-darker:active,
button.text-gray-darker:focus {
  color: #393939;
  opacity: .75;
}
.text-gray-light {
  color: #f3f3f3;
}
a.text-gray-light:hover,
a.text-gray-light:active,
a.text-gray-light:focus,
button.text-gray-light:hover,
button.text-gray-light:active,
button.text-gray-light:focus {
  color: #f3f3f3;
  opacity: .75;
}
.text-gray-lighter {
  color: #f9f9f9;
}
a.text-gray-lighter:hover,
a.text-gray-lighter:active,
a.text-gray-lighter:focus,
button.text-gray-lighter:hover,
button.text-gray-lighter:active,
button.text-gray-lighter:focus {
  color: #f9f9f9;
  opacity: .75;
}
.bg-muted {
  background-color: #999999;
}
a.bg-muted:hover,
a.bg-muted:focus {
  background-color: #808080;
}
.bg-primary {
  background-color: #5c90d2;
}
a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #3675c5;
}
.bg-primary-op {
  background-color: rgba(92, 144, 210, 0.75);
}
a.bg-primary-op:hover,
a.bg-primary-op:focus {
  background-color: rgba(54, 117, 197, 0.75);
}
.bg-primary-dark {
  background-color: #3e4a59;
}
a.bg-primary-dark:hover,
a.bg-primary-dark:focus {
  background-color: #29313b;
}
.bg-primary-dark-op {
  background-color: rgba(62, 74, 89, 0.83);
}
a.bg-primary-dark-op:hover,
a.bg-primary-dark-op:focus {
  background-color: rgba(41, 49, 59, 0.83);
}
.bg-primary-darker {
  background-color: #2c343f;
}
a.bg-primary-darker:hover,
a.bg-primary-darker:focus {
  background-color: #171b21;
}
.bg-primary-light {
  background-color: #98b9e3;
}
a.bg-primary-light:hover,
a.bg-primary-light:focus {
  background-color: #709ed8;
}
.bg-primary-lighter {
  background-color: #ccdcf1;
}
a.bg-primary-lighter:hover,
a.bg-primary-lighter:focus {
  background-color: #a4c1e6;
}
.bg-success {
  background-color: #46c37b;
}
a.bg-success:hover,
a.bg-success:focus {
  background-color: #34a263;
}
.bg-warning {
  background-color: #f3b760;
}
a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #efa231;
}
.bg-info {
  background-color: #70b9eb;
}
a.bg-info:hover,
a.bg-info:focus {
  background-color: #43a3e5;
}
.bg-danger {
  background-color: #d26a5c;
}
a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #c54736;
}
.bg-success-light {
  background-color: #e0f5e9;
}
a.bg-success-light:hover,
a.bg-success-light:focus {
  background-color: #b9e9ce;
}
.bg-warning-light {
  background-color: #fdf3e5;
}
a.bg-warning-light:hover,
a.bg-warning-light:focus {
  background-color: #f9ddb6;
}
.bg-info-light {
  background-color: #edf6fd;
}
a.bg-info-light:hover,
a.bg-info-light:focus {
  background-color: #bfdff8;
}
.bg-danger-light {
  background-color: #f9eae8;
}
a.bg-danger-light:hover,
a.bg-danger-light:focus {
  background-color: #eec5c0;
}
.bg-white {
  background-color: #fff;
}
a.bg-white:hover,
a.bg-white:focus {
  background-color: #e6e6e6;
}
.bg-white-op {
  background-color: rgba(255, 255, 255, 0.075);
}
.bg-crystal-op {
  background-color: rgba(255, 255, 255, 0.15);
}
.bg-black {
  background-color: #000;
}
a.bg-black:hover,
a.bg-black:focus {
  background-color: #000000;
}
.bg-black-op {
  background-color: rgba(0, 0, 0, 0.4);
}
.bg-gray {
  background-color: #c9c9c9;
}
a.bg-gray:hover,
a.bg-gray:focus {
  background-color: #b0b0b0;
}
.bg-gray-dark {
  background-color: #999999;
}
a.bg-gray-dark:hover,
a.bg-gray-dark:focus {
  background-color: #808080;
}
.bg-gray-darker {
  background-color: #393939;
}
a.bg-gray-darker:hover,
a.bg-gray-darker:focus {
  background-color: #202020;
}
.bg-gray-light {
  background-color: #f3f3f3;
}
a.bg-gray-light:hover,
a.bg-gray-light:focus {
  background-color: #dadada;
}
.bg-gray-lighter {
  background-color: #f9f9f9;
}
a.bg-gray-lighter:hover,
a.bg-gray-lighter:focus {
  background-color: #e0e0e0;
}
.btn {
  font-weight: 600;
  border-radius: 2px;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.btn:active,
.btn.active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-square {
  border-radius: 0;
}
.btn.btn-rounded {
  border-radius: 20px;
}
.btn.btn-minw {
  min-width: 110px;
}
.btn.btn-noborder {
  border: none !important;
}
.btn.btn-image {
  position: relative;
  padding-left: 40px;
}
.btn.btn-image > img {
  position: absolute;
  top: 3px;
  left: 3px;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 3px;
}
.btn > i.pull-left {
  margin-top: 3px;
  margin-right: 5px;
}
.btn > i.pull-right {
  margin-top: 3px;
  margin-left: 5px;
}
.btn-link,
.btn-link:hover,
.btn-link:focus {
  text-decoration: none;
}
.btn-default {
  color: #545454;
  background-color: #f5f5f5;
  border-color: #e9e9e9;
}
.btn-default:focus,
.btn-default.focus,
.btn-default:hover {
  color: #545454;
  background-color: #e1e1e1;
  border-color: #cacaca;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #545454;
  background-color: #c7c7c7;
  border-color: #b1b1b1;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #545454;
  background-color: #c7c7c7;
  border-color: #b1b1b1;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #f5f5f5;
  border-color: #e9e9e9;
}
.btn-default .badge {
  color: #f5f5f5;
  background-color: #545454;
}
.btn-primary {
  color: #fff;
  background-color: #5c90d2;
  border-color: #3675c5;
}
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:hover {
  color: #fff;
  background-color: #3c7ac9;
  border-color: #295995;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #2d62a5;
  border-color: #1e416d;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #fff;
  background-color: #2d62a5;
  border-color: #1e416d;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #5c90d2;
  border-color: #3675c5;
}
.btn-primary .badge {
  color: #5c90d2;
  background-color: #fff;
}
.btn-success {
  color: #fff;
  background-color: #46c37b;
  border-color: #34a263;
}
.btn-success:focus,
.btn-success.focus,
.btn-success:hover {
  color: #fff;
  background-color: #37a967;
  border-color: #257346;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #2a8350;
  border-color: #194d2f;
}
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  color: #fff;
  background-color: #2a8350;
  border-color: #194d2f;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #46c37b;
  border-color: #34a263;
}
.btn-success .badge {
  color: #46c37b;
  background-color: #fff;
}
.btn-info {
  color: #fff;
  background-color: #70b9eb;
  border-color: #43a3e5;
}
.btn-info:focus,
.btn-info.focus,
.btn-info:hover {
  color: #fff;
  background-color: #4ca7e6;
  border-color: #1d86ce;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #1f92e0;
  border-color: #1769a1;
}
.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: #1f92e0;
  border-color: #1769a1;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #70b9eb;
  border-color: #43a3e5;
}
.btn-info .badge {
  color: #70b9eb;
  background-color: #fff;
}
.btn-warning {
  color: #fff;
  background-color: #f3b760;
  border-color: #efa231;
}
.btn-warning:focus,
.btn-warning.focus,
.btn-warning:hover {
  color: #fff;
  background-color: #f0a63a;
  border-color: #d38310;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #e68f11;
  border-color: #a3660c;
}
.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
  color: #fff;
  background-color: #e68f11;
  border-color: #a3660c;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #f3b760;
  border-color: #efa231;
}
.btn-warning .badge {
  color: #f3b760;
  background-color: #fff;
}
.btn-danger {
  color: #fff;
  background-color: #d26a5c;
  border-color: #c54736;
}
.btn-danger:focus,
.btn-danger.focus,
.btn-danger:hover {
  color: #fff;
  background-color: #c94d3c;
  border-color: #953629;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #a53c2d;
  border-color: #6d271e;
}
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  color: #fff;
  background-color: #a53c2d;
  border-color: #6d271e;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #d26a5c;
  border-color: #c54736;
}
.btn-danger .badge {
  color: #d26a5c;
  background-color: #fff;
}
label {
  font-size: 13px;
  font-weight: 600;
}
.form-control {
  color: #646464;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.form-control::-moz-placeholder {
  color: #aaa;
}
.form-control:-ms-input-placeholder {
  color: #aaa;
}
.form-control::-webkit-input-placeholder {
  color: #aaa;
}
.form-control:focus {
  border-color: #ccc;
  background-color: #fcfcfc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea.form-control {
  max-width: 100%;
}
input[type="text"].form-control,
input[type="password"].form-control,
input[type="email"].form-control {
  -webkit-appearance: none;
}
.form-control.input-sm {
  border-radius: 3px;
}
.form-control.input-lg {
  font-size: 14px;
  border-radius: 3px;
}
.input-group-lg .form-control {
  font-size: 14px;
}
.form-group {
  margin-bottom: 20px;
}
.form-bordered .form-group {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f3f3f3;
}
.form-bordered .form-group.form-actions {
  border-bottom: none;
}
.help-block {
  margin-top: 5px;
  margin-bottom: 0;
  font-style: italic;
  font-size: 13px;
  color: #a4a4a4;
}
.has-success > label,
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #46c37b;
}
.has-success .form-control {
  border-color: #46c37b;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .form-control:focus {
  border-color: #34a263;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .input-group-addon {
  color: #46c37b;
  border-color: #46c37b;
  background-color: #fff;
}
.has-success .form-control-feedback {
  color: #46c37b;
}
.has-info > label,
.has-info .help-block,
.has-info .control-label,
.has-info .radio,
.has-info .checkbox,
.has-info .radio-inline,
.has-info .checkbox-inline,
.has-info.radio label,
.has-info.checkbox label,
.has-info.radio-inline label,
.has-info.checkbox-inline label {
  color: #70b9eb;
}
.has-info .form-control {
  border-color: #70b9eb;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-info .form-control:focus {
  border-color: #43a3e5;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-info .input-group-addon {
  color: #70b9eb;
  border-color: #70b9eb;
  background-color: #fff;
}
.has-info .form-control-feedback {
  color: #70b9eb;
}
.has-warning > label,
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #f3b760;
}
.has-warning .form-control {
  border-color: #f3b760;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .form-control:focus {
  border-color: #efa231;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .input-group-addon {
  color: #f3b760;
  border-color: #f3b760;
  background-color: #fff;
}
.has-warning .form-control-feedback {
  color: #f3b760;
}
.has-error > label,
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #d26a5c;
}
.has-error .form-control {
  border-color: #d26a5c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .form-control:focus {
  border-color: #c54736;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .input-group-addon {
  color: #d26a5c;
  border-color: #d26a5c;
  background-color: #fff;
}
.has-error .form-control-feedback {
  color: #d26a5c;
}
.input-group-addon {
  color: #646464;
  background-color: #f9f9f9;
  border-color: #e6e6e6;
  border-radius: 3px;
}
.input-group-addon.input-sm,
.input-group-addon.input-lg {
  border-radius: 3px;
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  border-radius: 3px;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group-addon:first-child {
  border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group-addon:last-child {
  border-left: 0;
}
.label-primary {
  background-color: #5c90d2;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #3675c5;
}
.label-success {
  background-color: #46c37b;
}
.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #34a263;
}
.label-info {
  background-color: #70b9eb;
}
.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #43a3e5;
}
.label-warning {
  background-color: #f3b760;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #efa231;
}
.label-danger {
  background-color: #d26a5c;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c54736;
}
.badge-primary {
  background-color: #5c90d2;
}
.badge-success {
  background-color: #46c37b;
}
.badge-info {
  background-color: #70b9eb;
}
.badge-warning {
  background-color: #f3b760;
}
.badge-danger {
  background-color: #d26a5c;
}
.alert {
  padding-bottom: 10px;
  border-radius: 2px;
  border: none;
}
.alert p {
  margin-bottom: 5px !important;
}
.alert-success {
  background-color: #e0f5e9;
  color: #34a263;
  -webkit-box-shadow: 0 2px #cdefdb;
  box-shadow: 0 2px #cdefdb;
}
.alert-success hr {
  border-top-color: #cdefdb;
}
.alert-success .alert-link {
  color: #287b4b;
}
.alert-info {
  background-color: #edf6fd;
  color: #43a3e5;
  -webkit-box-shadow: 0 2px #d6ebfa;
  box-shadow: 0 2px #d6ebfa;
}
.alert-info hr {
  border-top-color: #d6ebfa;
}
.alert-info .alert-link {
  color: #1e8cd7;
}
.alert-warning {
  background-color: #fdf3e5;
  color: #efa231;
  -webkit-box-shadow: 0 2px #fbe8cd;
  box-shadow: 0 2px #fbe8cd;
}
.alert-warning hr {
  border-top-color: #fbe8cd;
}
.alert-warning .alert-link {
  color: #dc8911;
}
.alert-danger {
  background-color: #f9eae8;
  color: #c54736;
  -webkit-box-shadow: 0 2px #f4d8d4;
  box-shadow: 0 2px #f4d8d4;
}
.alert-danger hr {
  border-top-color: #f4d8d4;
}
.alert-danger .alert-link {
  color: #9d392b;
}
.progress {
  height: 24px;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress.progress-mini {
  height: 5px;
}
.progress.progress-mini .progress-bar {
  line-height: 5px;
}
.progress-bar {
  line-height: 24px;
  font-weight: 600;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-bar-primary {
  background-color: #5c90d2;
}
.progress-bar-success {
  background-color: #46c37b;
}
.progress-bar-info {
  background-color: #70b9eb;
}
.progress-bar-warning {
  background-color: #f3b760;
}
.progress-bar-danger {
  background-color: #d26a5c;
}
.nav-pills > li > a {
  font-weight: 600;
  color: #646464;
  border-radius: 3px;
}
.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
  color: #646464;
  background-color: #f9f9f9;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #5c90d2;
}
.nav-pills > li.active > a > .badge {
  color: #5c90d2;
}
.pagination {
  border-radius: 0;
}
.pagination > li {
  display: block;
  float: left;
  margin: 0 0 5px 5px;
}
.pagination > li > a,
.pagination > li > span {
  display: block;
  float: none;
  margin: 0;
  padding-right: 8px;
  padding-left: 8px;
  color: #646464;
  font-weight: 600;
  border: none;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #5c90d2;
  background-color: transparent;
  -webkit-box-shadow: 0 2px #5c90d2;
  box-shadow: 0 2px #5c90d2;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  color: #5c90d2;
  background-color: #f9f9f9;
  -webkit-box-shadow: 0 2px #5c90d2;
  box-shadow: 0 2px #5c90d2;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #c9c9c9;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.pager li > a,
.pager li > span {
  padding: 6px 14px;
  font-weight: 600;
  color: #646464;
  border: 1px solid #eee;
  border-radius: 3px;
}
.pager li > a:hover,
.pager li > a:focus {
  color: #5c90d2;
  background-color: #f9f9f9;
}
.pager li.disabled > span,
.pager li.disabled > span:hover,
.pager li.disabled > span:focus,
.pager li.disabled > a,
.pager li.disabled > a:hover,
.pager li.disabled > a:focus {
  color: #c9c9c9;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.list-group-item {
  padding: 10px 15px;
  border-color: #eee;
}
a.list-group-item {
  font-weight: 600;
  color: #646464;
}
a.list-group-item:hover,
a.list-group-item:focus {
  color: #5c90d2;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #5c90d2;
  border-color: #5c90d2;
}
.list-group-item.active > .badge {
  color: #5c90d2;
}
.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #999999;
  background-color: transparent;
}
.breadcrumb > li + li:before {
  display: inline-block;
  padding: 0;
  font-family: "FontAwesome";
  color: rgba(0, 0, 0, 0.5);
  content: "\f105";
  width: 20px;
  text-align: center;
}
.tooltip-inner {
  padding: 6px 8px;
  background-color: #2c343f;
  border-radius: 0;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #2c343f;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #2c343f;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #2c343f;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #2c343f;
}
.popover {
  border-color: #ddd;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}
.popover-title {
  padding: 10px 10px 1px;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #fff;
  border-bottom: none;
  border-radius: 1px 1px 0 0;
}
.popover-content {
  padding: 10px;
}
.dropdown-menu {
  min-width: 180px;
  padding: 5px 0;
  border-color: #ddd;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}
.dropdown-menu > li > a {
  padding: 7px 12px;
}
.dropdown-menu > li > a:hover {
  background-color: #f9f9f9;
}
.dropdown-menu > li > a i.pull-right,
.dropdown-menu > li > a .badge.pull-right {
  right: 0;
  margin-top: 3px;
  margin-left: 10px;
}
.dropdown-menu > li > a i.pull-left,
.dropdown-menu > li > a .badge.pull-left {
  left: 0;
  margin-top: 3px;
  margin-right: 10px;
}
.dropdown-menu > li > a .badge.pull-right {
  margin-top: 1px;
}
.dropdown-menu > li > a .badge.pull-left {
  margin-top: 1px;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #545454;
  background-color: #f0f0f0;
}
.dropdown-header {
  padding: 5px 12px 4px;
  font-weight: 600;
  color: #999999;
  text-transform: uppercase;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 12px 10px;
  border-top: 1px solid #f0f0f0;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th {
  padding: 16px 10px 12px;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.table > thead > tr > th {
  border-bottom: 1px solid #ddd;
}
.table > tbody + tbody {
  border-top: 1px solid #ddd;
}
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 6px 8px;
}
.table-bordered {
  border: 1px solid #e9e9e9;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #f0f0f0;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
  border-bottom-color: #e9e9e9;
}
.table-borderless {
  border: none;
}
.table-borderless > thead > tr > th,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > tbody > tr > td,
.table-borderless > tfoot > tr > td {
  border: none;
}
.table-borderless > thead > tr > th,
.table-borderless > thead > tr > td {
  border-bottom: 1px solid #ddd;
}
.table-vcenter > thead > tr > th,
.table-vcenter > tbody > tr > th,
.table-vcenter > tfoot > tr > th,
.table-vcenter > thead > tr > td,
.table-vcenter > tbody > tr > td,
.table-vcenter > tfoot > tr > td {
  vertical-align: middle;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}
.table-header-bg > thead > tr > th,
.table-header-bg > thead > tr > td {
  color: #fff;
  background-color: #5c90d2;
  border-bottom-color: #5c90d2;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f9f9f9;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #ececec;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #e0f5e9;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #cdefdb;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #edf6fd;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #d6ebfa;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fdf3e5;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #fbe8cd;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f9eae8;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #f4d8d4;
}
.table-responsive {
  -webkit-overflow-scrolling: touch;
}
.js-table-checkable tbody tr,
.js-table-sections-header > tr {
  cursor: pointer;
}
.js-table-sections-header > tr > td:first-child > i {
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
}
.js-table-sections-header + tbody {
  display: none;
}
.js-table-sections-header.open > tr {
  background-color: #f9f9f9;
}
.js-table-sections-header.open > tr > td:first-child > i {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.js-table-sections-header.open + tbody {
  display: table-row-group;
}
.modal.fade .modal-dialog {
  -webkit-transition: all 0.12s ease-out;
  transition: all 0.12s ease-out;
}
.modal.fade .modal-dialog.modal-dialog-popin {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.modal.fade .modal-dialog.modal-dialog-popout {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
.modal.fade .modal-dialog.modal-dialog-slideup {
  -webkit-transform: translate(0, 10%);
  -ms-transform: translate(0, 10%);
  transform: translate(0, 10%);
}
.modal.fade .modal-dialog.modal-dialog-slideright {
  -webkit-transform: translate(-10%, 0);
  -ms-transform: translate(-10%, 0);
  transform: translate(-10%, 0);
}
.modal.fade .modal-dialog.modal-dialog-slideleft {
  -webkit-transform: translate(10%, 0);
  -ms-transform: translate(10%, 0);
  transform: translate(10%, 0);
}
.modal.fade .modal-dialog.modal-dialog-fromright {
  -webkit-transform: translateX(25%) rotate(10deg) scale(0.9);
  -ms-transform: translateX(25%) rotate(10deg) scale(0.9);
  transform: translateX(25%) rotate(10deg) scale(0.9);
}
.modal.fade .modal-dialog.modal-dialog-fromleft {
  -webkit-transform: translateX(-25%) rotate(-10deg) scale(0.9);
  -ms-transform: translateX(-25%) rotate(-10deg) scale(0.9);
  transform: translateX(-25%) rotate(-10deg) scale(0.9);
}
.modal.in .modal-dialog.modal-dialog-popin,
.modal.in .modal-dialog.modal-dialog-popout {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.modal.in .modal-dialog.modal-dialog-slideup,
.modal.in .modal-dialog.modal-dialog-slideright,
.modal.in .modal-dialog.modal-dialog-slideleft {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal.in .modal-dialog.modal-dialog-fromright,
.modal.in .modal-dialog.modal-dialog-fromleft {
  -webkit-transform: translateX(0) rotate(0) scale(1);
  -ms-transform: translateX(0) rotate(0) scale(1);
  transform: translateX(0) rotate(0) scale(1);
}
.modal-dialog.modal-dialog-top {
  margin-top: 0;
  padding: 0 !important;
}
.modal-dialog.modal-dialog-top .modal-content {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.modal-content {
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-backdrop.in {
  opacity: .25;
}
.modal-header {
  padding: 18px 20px;
  border-bottom-color: #eee;
}
.modal-body {
  padding: 20px;
}
.modal-footer {
  padding: 12px 20px;
  border-top-color: #eee;
}
.modal-title {
  font-weight: normal;
}
.fade.fade-up {
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
}
.fade.fade-up.in {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.fade.fade-right {
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
}
.fade.fade-right.in {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.fade.fade-left {
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
}
.fade.fade-left.in {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.panel {
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel-group .panel {
  border-radius: 3px;
}
.panel-group .panel + .panel {
  margin-top: 10px;
}
.panel-default {
  border-color: #f0f0f0;
}
.panel-default > .panel-heading {
  color: #646464;
  background-color: #f9f9f9;
  border-color: #f0f0f0;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #f0f0f0;
}
.panel-default > .panel-heading .badge {
  color: #f9f9f9;
  background-color: #646464;
}
.panel-default > .panel-heading a {
  font-weight: 400;
}
.panel-default > .panel-heading a:hover,
.panel-default > .panel-heading a:focus {
  color: #4a4a4a;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #f0f0f0;
}
.panel-primary {
  border-color: #ccdcf1;
}
.panel-primary > .panel-heading {
  color: #5c90d2;
  background-color: #e8eff9;
  border-color: #ccdcf1;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ccdcf1;
}
.panel-primary > .panel-heading .badge {
  color: #e8eff9;
  background-color: #5c90d2;
}
.panel-primary > .panel-heading a {
  font-weight: 400;
}
.panel-primary > .panel-heading a:hover,
.panel-primary > .panel-heading a:focus {
  color: #3675c5;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ccdcf1;
}
.panel-success {
  border-color: #cdefdb;
}
.panel-success > .panel-heading {
  color: #46c37b;
  background-color: #e0f5e9;
  border-color: #cdefdb;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #cdefdb;
}
.panel-success > .panel-heading .badge {
  color: #e0f5e9;
  background-color: #46c37b;
}
.panel-success > .panel-heading a {
  font-weight: 400;
}
.panel-success > .panel-heading a:hover,
.panel-success > .panel-heading a:focus {
  color: #34a263;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #cdefdb;
}
.panel-info {
  border-color: #d6ebfa;
}
.panel-info > .panel-heading {
  color: #70b9eb;
  background-color: #edf6fd;
  border-color: #d6ebfa;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6ebfa;
}
.panel-info > .panel-heading .badge {
  color: #edf6fd;
  background-color: #70b9eb;
}
.panel-info > .panel-heading a {
  font-weight: 400;
}
.panel-info > .panel-heading a:hover,
.panel-info > .panel-heading a:focus {
  color: #43a3e5;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6ebfa;
}
.panel-warning {
  border-color: #fbe8cd;
}
.panel-warning > .panel-heading {
  color: #f3b760;
  background-color: #fdf3e5;
  border-color: #fbe8cd;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #fbe8cd;
}
.panel-warning > .panel-heading .badge {
  color: #fdf3e5;
  background-color: #f3b760;
}
.panel-warning > .panel-heading a {
  font-weight: 400;
}
.panel-warning > .panel-heading a:hover,
.panel-warning > .panel-heading a:focus {
  color: #efa231;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #fbe8cd;
}
.panel-danger {
  border-color: #f4d8d4;
}
.panel-danger > .panel-heading {
  color: #d26a5c;
  background-color: #f9eae8;
  border-color: #f4d8d4;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #f4d8d4;
}
.panel-danger > .panel-heading .badge {
  color: #f9eae8;
  background-color: #d26a5c;
}
.panel-danger > .panel-heading a {
  font-weight: 400;
}
.panel-danger > .panel-heading a:hover,
.panel-danger > .panel-heading a:focus {
  color: #c54736;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #f4d8d4;
}
.img-responsive {
  width: 100%;
}
@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=4.5.0');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.5.0') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff2?v=4.5.0') format('woff2'), url('../fonts/fontawesome-webfont.woff?v=4.5.0') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.5.0') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-fw {
  width: 1.28571429em;
  text-align: center;
}
.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.85714286em;
}
.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left {
  margin-right: .3em;
}
.fa.fa-pull-right {
  margin-left: .3em;
}
/* Deprecated as of 4.4.0 */
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.fa.pull-left {
  margin-right: .3em;
}
.fa.pull-right {
  margin-left: .3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #ffffff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: "\f000";
}
.fa-music:before {
  content: "\f001";
}
.fa-search:before {
  content: "\f002";
}
.fa-envelope-o:before {
  content: "\f003";
}
.fa-heart:before {
  content: "\f004";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-o:before {
  content: "\f006";
}
.fa-user:before {
  content: "\f007";
}
.fa-film:before {
  content: "\f008";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-check:before {
  content: "\f00c";
}
.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\f00d";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-signal:before {
  content: "\f012";
}
.fa-gear:before,
.fa-cog:before {
  content: "\f013";
}
.fa-trash-o:before {
  content: "\f014";
}
.fa-home:before {
  content: "\f015";
}
.fa-file-o:before {
  content: "\f016";
}
.fa-clock-o:before {
  content: "\f017";
}
.fa-road:before {
  content: "\f018";
}
.fa-download:before {
  content: "\f019";
}
.fa-arrow-circle-o-down:before {
  content: "\f01a";
}
.fa-arrow-circle-o-up:before {
  content: "\f01b";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-play-circle-o:before {
  content: "\f01d";
}
.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e";
}
.fa-refresh:before {
  content: "\f021";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-lock:before {
  content: "\f023";
}
.fa-flag:before {
  content: "\f024";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-print:before {
  content: "\f02f";
}
.fa-camera:before {
  content: "\f030";
}
.fa-font:before {
  content: "\f031";
}
.fa-bold:before {
  content: "\f032";
}
.fa-italic:before {
  content: "\f033";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-list:before {
  content: "\f03a";
}
.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-video-camera:before {
  content: "\f03d";
}
.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "\f03e";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-tint:before {
  content: "\f043";
}
.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044";
}
.fa-share-square-o:before {
  content: "\f045";
}
.fa-check-square-o:before {
  content: "\f046";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-play:before {
  content: "\f04b";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-eject:before {
  content: "\f052";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-times-circle-o:before {
  content: "\f05c";
}
.fa-check-circle-o:before {
  content: "\f05d";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-mail-forward:before,
.fa-share:before {
  content: "\f064";
}
.fa-expand:before {
  content: "\f065";
}
.fa-compress:before {
  content: "\f066";
}
.fa-plus:before {
  content: "\f067";
}
.fa-minus:before {
  content: "\f068";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-plane:before {
  content: "\f072";
}
.fa-calendar:before {
  content: "\f073";
}
.fa-random:before {
  content: "\f074";
}
.fa-comment:before {
  content: "\f075";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-key:before {
  content: "\f084";
}
.fa-gears:before,
.fa-cogs:before {
  content: "\f085";
}
.fa-comments:before {
  content: "\f086";
}
.fa-thumbs-o-up:before {
  content: "\f087";
}
.fa-thumbs-o-down:before {
  content: "\f088";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-heart-o:before {
  content: "\f08a";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-linkedin-square:before {
  content: "\f08c";
}
.fa-thumb-tack:before {
  content: "\f08d";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-upload:before {
  content: "\f093";
}
.fa-lemon-o:before {
  content: "\f094";
}
.fa-phone:before {
  content: "\f095";
}
.fa-square-o:before {
  content: "\f096";
}
.fa-bookmark-o:before {
  content: "\f097";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}
.fa-github:before {
  content: "\f09b";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-feed:before,
.fa-rss:before {
  content: "\f09e";
}
.fa-hdd-o:before {
  content: "\f0a0";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-hand-o-right:before {
  content: "\f0a4";
}
.fa-hand-o-left:before {
  content: "\f0a5";
}
.fa-hand-o-up:before {
  content: "\f0a6";
}
.fa-hand-o-down:before {
  content: "\f0a7";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-group:before,
.fa-users:before {
  content: "\f0c0";
}
.fa-chain:before,
.fa-link:before {
  content: "\f0c1";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4";
}
.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "\f0c9";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-plus:before {
  content: "\f0d5";
}
.fa-money:before {
  content: "\f0d6";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-down:before,
.fa-sort-desc:before {
  content: "\f0dd";
}
.fa-sort-up:before,
.fa-sort-asc:before {
  content: "\f0de";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2";
}
.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3";
}
.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-comment-o:before {
  content: "\f0e5";
}
.fa-comments-o:before {
  content: "\f0e6";
}
.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea";
}
.fa-lightbulb-o:before {
  content: "\f0eb";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-bell-o:before {
  content: "\f0a2";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cutlery:before {
  content: "\f0f5";
}
.fa-file-text-o:before {
  content: "\f0f6";
}
.fa-building-o:before {
  content: "\f0f7";
}
.fa-hospital-o:before {
  content: "\f0f8";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b";
}
.fa-circle-o:before {
  content: "\f10c";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-circle:before {
  content: "\f111";
}
.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-folder-o:before {
  content: "\f114";
}
.fa-folder-open-o:before {
  content: "\f115";
}
.fa-smile-o:before {
  content: "\f118";
}
.fa-frown-o:before {
  content: "\f119";
}
.fa-meh-o:before {
  content: "\f11a";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-keyboard-o:before {
  content: "\f11c";
}
.fa-flag-o:before {
  content: "\f11d";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-code:before {
  content: "\f121";
}
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122";
}
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-crop:before {
  content: "\f125";
}
.fa-code-fork:before {
  content: "\f126";
}
.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127";
}
.fa-question:before {
  content: "\f128";
}
.fa-info:before {
  content: "\f129";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-shield:before {
  content: "\f132";
}
.fa-calendar-o:before {
  content: "\f133";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-minus-square-o:before {
  content: "\f147";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-pencil-square:before {
  content: "\f14b";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150";
}
.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151";
}
.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152";
}
.fa-euro:before,
.fa-eur:before {
  content: "\f153";
}
.fa-gbp:before {
  content: "\f154";
}
.fa-dollar:before,
.fa-usd:before {
  content: "\f155";
}
.fa-rupee:before,
.fa-inr:before {
  content: "\f156";
}
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157";
}
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158";
}
.fa-won:before,
.fa-krw:before {
  content: "\f159";
}
.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-text:before {
  content: "\f15c";
}
.fa-sort-alpha-asc:before {
  content: "\f15d";
}
.fa-sort-alpha-desc:before {
  content: "\f15e";
}
.fa-sort-amount-asc:before {
  content: "\f160";
}
.fa-sort-amount-desc:before {
  content: "\f161";
}
.fa-sort-numeric-asc:before {
  content: "\f162";
}
.fa-sort-numeric-desc:before {
  content: "\f163";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-youtube-square:before {
  content: "\f166";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-youtube-play:before {
  content: "\f16a";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-adn:before {
  content: "\f170";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitbucket-square:before {
  content: "\f172";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-apple:before {
  content: "\f179";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-android:before {
  content: "\f17b";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-trello:before {
  content: "\f181";
}
.fa-female:before {
  content: "\f182";
}
.fa-male:before {
  content: "\f183";
}
.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184";
}
.fa-sun-o:before {
  content: "\f185";
}
.fa-moon-o:before {
  content: "\f186";
}
.fa-archive:before {
  content: "\f187";
}
.fa-bug:before {
  content: "\f188";
}
.fa-vk:before {
  content: "\f189";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-arrow-circle-o-right:before {
  content: "\f18e";
}
.fa-arrow-circle-o-left:before {
  content: "\f190";
}
.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191";
}
.fa-dot-circle-o:before {
  content: "\f192";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195";
}
.fa-plus-square-o:before {
  content: "\f196";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-slack:before {
  content: "\f198";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "\f19c";
}
.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-pied-piper:before {
  content: "\f1a7";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-spoon:before {
  content: "\f1b1";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-automobile:before,
.fa-car:before {
  content: "\f1b9";
}
.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-file-pdf-o:before {
  content: "\f1c1";
}
.fa-file-word-o:before {
  content: "\f1c2";
}
.fa-file-excel-o:before {
  content: "\f1c3";
}
.fa-file-powerpoint-o:before {
  content: "\f1c4";
}
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "\f1c5";
}
.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "\f1c6";
}
.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "\f1c7";
}
.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8";
}
.fa-file-code-o:before {
  content: "\f1c9";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-circle-o-notch:before {
  content: "\f1ce";
}
.fa-ra:before,
.fa-rebel:before {
  content: "\f1d0";
}
.fa-ge:before,
.fa-empire:before {
  content: "\f1d1";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7";
}
.fa-send:before,
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "\f1d9";
}
.fa-history:before {
  content: "\f1da";
}
.fa-circle-thin:before {
  content: "\f1db";
}
.fa-header:before {
  content: "\f1dc";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-sliders:before {
  content: "\f1de";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "\f1e3";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-newspaper-o:before {
  content: "\f1ea";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bell-slash-o:before {
  content: "\f1f7";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-eyedropper:before {
  content: "\f1fb";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-area-chart:before {
  content: "\f1fe";
}
.fa-pie-chart:before {
  content: "\f200";
}
.fa-line-chart:before {
  content: "\f201";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bus:before {
  content: "\f207";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-cc:before {
  content: "\f20a";
}
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "\f20b";
}
.fa-meanpath:before {
  content: "\f20c";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-venus:before {
  content: "\f221";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-intersex:before,
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-facebook-official:before {
  content: "\f230";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-server:before {
  content: "\f233";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-hotel:before,
.fa-bed:before {
  content: "\f236";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-train:before {
  content: "\f238";
}
.fa-subway:before {
  content: "\f239";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-yc:before,
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-battery-4:before,
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-battery-2:before,
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\f244";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-sticky-note-o:before {
  content: "\f24a";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-hourglass-o:before {
  content: "\f250";
}
.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\f255";
}
.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "\f256";
}
.fa-hand-scissors-o:before {
  content: "\f257";
}
.fa-hand-lizard-o:before {
  content: "\f258";
}
.fa-hand-spock-o:before {
  content: "\f259";
}
.fa-hand-pointer-o:before {
  content: "\f25a";
}
.fa-hand-peace-o:before {
  content: "\f25b";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-safari:before {
  content: "\f267";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-tv:before,
.fa-television:before {
  content: "\f26c";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-500px:before {
  content: "\f26e";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-calendar-plus-o:before {
  content: "\f271";
}
.fa-calendar-minus-o:before {
  content: "\f272";
}
.fa-calendar-times-o:before {
  content: "\f273";
}
.fa-calendar-check-o:before {
  content: "\f274";
}
.fa-industry:before {
  content: "\f275";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-map-o:before {
  content: "\f278";
}
.fa-map:before {
  content: "\f279";
}
.fa-commenting:before {
  content: "\f27a";
}
.fa-commenting-o:before {
  content: "\f27b";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-vimeo:before {
  content: "\f27d";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-edge:before {
  content: "\f282";
}
.fa-credit-card-alt:before {
  content: "\f283";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-modx:before {
  content: "\f285";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-usb:before {
  content: "\f287";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-pause-circle-o:before {
  content: "\f28c";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stop-circle-o:before {
  content: "\f28e";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-percent:before {
  content: "\f295";
}
@font-face {
  font-family: Simple-Line-Icons;
  src: url(../fonts/Simple-Line-Icons.eot);
  src: url(../fonts/Simple-Line-Icons.eot?#iefix) format('embedded-opentype'), url(../fonts/Simple-Line-Icons.woff) format('woff'), url(../fonts/Simple-Line-Icons.ttf) format('truetype'), url(../fonts/Simple-Line-Icons.svg#Simple-Line-Icons) format('svg');
  font-weight: 400;
  font-style: normal;
}
.si {
  font-family: Simple-Line-Icons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.btn .si {
  position: relative;
  bottom: -2px;
  display: inline-block;
}
.si-user-female:before {
  content: "\e000";
}
.si-user-follow:before {
  content: "\e002";
}
.si-user-following:before {
  content: "\e003";
}
.si-user-unfollow:before {
  content: "\e004";
}
.si-trophy:before {
  content: "\e006";
}
.si-screen-smartphone:before {
  content: "\e010";
}
.si-screen-desktop:before {
  content: "\e011";
}
.si-plane:before {
  content: "\e012";
}
.si-notebook:before {
  content: "\e013";
}
.si-moustache:before {
  content: "\e014";
}
.si-mouse:before {
  content: "\e015";
}
.si-magnet:before {
  content: "\e016";
}
.si-energy:before {
  content: "\e020";
}
.si-emoticon-smile:before {
  content: "\e021";
}
.si-disc:before {
  content: "\e022";
}
.si-cursor-move:before {
  content: "\e023";
}
.si-crop:before {
  content: "\e024";
}
.si-credit-card:before {
  content: "\e025";
}
.si-chemistry:before {
  content: "\e026";
}
.si-user:before {
  content: "\e005";
}
.si-speedometer:before {
  content: "\e007";
}
.si-social-youtube:before {
  content: "\e008";
}
.si-social-twitter:before {
  content: "\e009";
}
.si-social-tumblr:before {
  content: "\e00a";
}
.si-social-facebook:before {
  content: "\e00b";
}
.si-social-dropbox:before {
  content: "\e00c";
}
.si-social-dribbble:before {
  content: "\e00d";
}
.si-shield:before {
  content: "\e00e";
}
.si-screen-tablet:before {
  content: "\e00f";
}
.si-magic-wand:before {
  content: "\e017";
}
.si-hourglass:before {
  content: "\e018";
}
.si-graduation:before {
  content: "\e019";
}
.si-ghost:before {
  content: "\e01a";
}
.si-game-controller:before {
  content: "\e01b";
}
.si-fire:before {
  content: "\e01c";
}
.si-eyeglasses:before {
  content: "\e01d";
}
.si-envelope-open:before {
  content: "\e01e";
}
.si-envelope-letter:before {
  content: "\e01f";
}
.si-bell:before {
  content: "\e027";
}
.si-badge:before {
  content: "\e028";
}
.si-anchor:before {
  content: "\e029";
}
.si-wallet:before {
  content: "\e02a";
}
.si-vector:before {
  content: "\e02b";
}
.si-speech:before {
  content: "\e02c";
}
.si-puzzle:before {
  content: "\e02d";
}
.si-printer:before {
  content: "\e02e";
}
.si-present:before {
  content: "\e02f";
}
.si-playlist:before {
  content: "\e030";
}
.si-pin:before {
  content: "\e031";
}
.si-picture:before {
  content: "\e032";
}
.si-map:before {
  content: "\e033";
}
.si-layers:before {
  content: "\e034";
}
.si-handbag:before {
  content: "\e035";
}
.si-globe-alt:before {
  content: "\e036";
}
.si-globe:before {
  content: "\e037";
}
.si-frame:before {
  content: "\e038";
}
.si-folder-alt:before {
  content: "\e039";
}
.si-film:before {
  content: "\e03a";
}
.si-feed:before {
  content: "\e03b";
}
.si-earphones-alt:before {
  content: "\e03c";
}
.si-earphones:before {
  content: "\e03d";
}
.si-drop:before {
  content: "\e03e";
}
.si-drawer:before {
  content: "\e03f";
}
.si-docs:before {
  content: "\e040";
}
.si-directions:before {
  content: "\e041";
}
.si-direction:before {
  content: "\e042";
}
.si-diamond:before {
  content: "\e043";
}
.si-cup:before {
  content: "\e044";
}
.si-compass:before {
  content: "\e045";
}
.si-call-out:before {
  content: "\e046";
}
.si-call-in:before {
  content: "\e047";
}
.si-call-end:before {
  content: "\e048";
}
.si-calculator:before {
  content: "\e049";
}
.si-bubbles:before {
  content: "\e04a";
}
.si-briefcase:before {
  content: "\e04b";
}
.si-book-open:before {
  content: "\e04c";
}
.si-basket-loaded:before {
  content: "\e04d";
}
.si-basket:before {
  content: "\e04e";
}
.si-bag:before {
  content: "\e04f";
}
.si-action-undo:before {
  content: "\e050";
}
.si-action-redo:before {
  content: "\e051";
}
.si-wrench:before {
  content: "\e052";
}
.si-umbrella:before {
  content: "\e053";
}
.si-trash:before {
  content: "\e054";
}
.si-tag:before {
  content: "\e055";
}
.si-support:before {
  content: "\e056";
}
.si-size-fullscreen:before {
  content: "\e057";
}
.si-size-actual:before {
  content: "\e058";
}
.si-shuffle:before {
  content: "\e059";
}
.si-share-alt:before {
  content: "\e05a";
}
.si-share:before {
  content: "\e05b";
}
.si-rocket:before {
  content: "\e05c";
}
.si-question:before {
  content: "\e05d";
}
.si-pie-chart:before {
  content: "\e05e";
}
.si-pencil:before {
  content: "\e05f";
}
.si-note:before {
  content: "\e060";
}
.si-music-tone-alt:before {
  content: "\e061";
}
.si-music-tone:before {
  content: "\e062";
}
.si-microphone:before {
  content: "\e063";
}
.si-loop:before {
  content: "\e064";
}
.si-logout:before {
  content: "\e065";
}
.si-login:before {
  content: "\e066";
}
.si-list:before {
  content: "\e067";
}
.si-like:before {
  content: "\e068";
}
.si-home:before {
  content: "\e069";
}
.si-grid:before {
  content: "\e06a";
}
.si-graph:before {
  content: "\e06b";
}
.si-equalizer:before {
  content: "\e06c";
}
.si-dislike:before {
  content: "\e06d";
}
.si-cursor:before {
  content: "\e06e";
}
.si-control-start:before {
  content: "\e06f";
}
.si-control-rewind:before {
  content: "\e070";
}
.si-control-play:before {
  content: "\e071";
}
.si-control-pause:before {
  content: "\e072";
}
.si-control-forward:before {
  content: "\e073";
}
.si-control-end:before {
  content: "\e074";
}
.si-calendar:before {
  content: "\e075";
}
.si-bulb:before {
  content: "\e076";
}
.si-bar-chart:before {
  content: "\e077";
}
.si-arrow-up:before {
  content: "\e078";
}
.si-arrow-right:before {
  content: "\e079";
}
.si-arrow-left:before {
  content: "\e07a";
}
.si-arrow-down:before {
  content: "\e07b";
}
.si-ban:before {
  content: "\e07c";
}
.si-bubble:before {
  content: "\e07d";
}
.si-camcorder:before {
  content: "\e07e";
}
.si-camera:before {
  content: "\e07f";
}
.si-check:before {
  content: "\e080";
}
.si-clock:before {
  content: "\e081";
}
.si-close:before {
  content: "\e082";
}
.si-cloud-download:before {
  content: "\e083";
}
.si-cloud-upload:before {
  content: "\e084";
}
.si-doc:before {
  content: "\e085";
}
.si-envelope:before {
  content: "\e086";
}
.si-eye:before {
  content: "\e087";
}
.si-flag:before {
  content: "\e088";
}
.si-folder:before {
  content: "\e089";
}
.si-heart:before {
  content: "\e08a";
}
.si-info:before {
  content: "\e08b";
}
.si-key:before {
  content: "\e08c";
}
.si-link:before {
  content: "\e08d";
}
.si-lock:before {
  content: "\e08e";
}
.si-lock-open:before {
  content: "\e08f";
}
.si-magnifier:before {
  content: "\e090";
}
.si-magnifier-add:before {
  content: "\e091";
}
.si-magnifier-remove:before {
  content: "\e092";
}
.si-paper-clip:before {
  content: "\e093";
}
.si-paper-plane:before {
  content: "\e094";
}
.si-plus:before {
  content: "\e095";
}
.si-pointer:before {
  content: "\e096";
}
.si-power:before {
  content: "\e097";
}
.si-refresh:before {
  content: "\e098";
}
.si-reload:before {
  content: "\e099";
}
.si-settings:before {
  content: "\e09a";
}
.si-star:before {
  content: "\e09b";
}
.si-symbol-female:before {
  content: "\e09c";
}
.si-symbol-male:before {
  content: "\e09d";
}
.si-target:before {
  content: "\e09e";
}
.si-volume-1:before {
  content: "\e09f";
}
.si-volume-2:before {
  content: "\e0a0";
}
.si-volume-off:before {
  content: "\e0a1";
}
.si-users:before {
  content: "\e001";
}
#page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 999998;
}
#page-loader:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  margin-top: -30px;
  margin-left: -30px;
  width: 60px;
  height: 60px;
  background-color: #5c90d2;
  border-radius: 100%;
  content: '';
  z-index: 999999;
  -webkit-animation: page-loader 0.9s infinite ease-in-out;
  animation: page-loader 0.9s infinite ease-in-out;
}
.ie9 #page-loader:after {
  text-align: center;
  content: 'Loading..';
  background-color: transparent;
}
@-webkit-keyframes page-loader {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes page-loader {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
#header-navbar {
  min-height: 60px;
  background-color: #fff;
}
#header-navbar:before,
#header-navbar:after {
  content: " ";
  display: table;
}
#header-navbar:after {
  clear: both;
}
.header-navbar-fixed #header-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  min-width: 320px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
}
.header-navbar-fixed #main-container {
  padding-top: 60px;
}
@media screen and (min-width: 992px) {
  .header-navbar-fixed.sidebar-l.sidebar-o #header-navbar {
    left: 230px;
  }
  .header-navbar-fixed.sidebar-r.sidebar-o #header-navbar {
    right: 230px;
  }
  .header-navbar-fixed.sidebar-l.sidebar-o.sidebar-mini #header-navbar {
    left: 60px;
  }
  .header-navbar-fixed.sidebar-r.sidebar-o.sidebar-mini #header-navbar {
    right: 60px;
  }
}
.header-navbar-transparent #header-navbar {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.header-navbar-transparent.header-navbar-fixed.header-navbar-scroll #header-navbar {
  background-color: #3e4a59;
}
.header-navbar-transparent.header-navbar-fixed #main-container {
  padding-top: 0;
}
#page-container {
  margin: 0 auto;
  width: 100%;
  min-width: 320px;
  background-color: #2c343f;
}
@media screen and (min-width: 992px) {
  #page-container.sidebar-l.sidebar-o {
    padding-left: 230px;
  }
  #page-container.sidebar-r.sidebar-o {
    padding-right: 230px;
  }
  #page-container.sidebar-l.sidebar-o.sidebar-mini {
    padding-left: 60px;
  }
  #page-container.sidebar-r.sidebar-o.sidebar-mini {
    padding-right: 60px;
  }
}
#sidebar,
#side-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1032;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 0.28s ease-out;
  transition: all 0.28s ease-out;
}
@media screen and (min-width: 992px) {
  .side-scroll #sidebar,
  .side-scroll #side-overlay {
    overflow-y: hidden;
  }
}
#sidebar {
  width: 230px;
  background-color: #2c343f;
}
.sidebar-l #sidebar {
  left: 0;
  -webkit-transform: translateX(-100%) translateY(0) translateZ(0);
  -ms-transform: translateX(-100%) translateY(0);
  transform: translateX(-100%) translateY(0) translateZ(0);
}
.sidebar-r #sidebar {
  right: 0;
  -webkit-transform: translateX(100%) translateY(0) translateZ(0);
  -ms-transform: translateX(100%) translateY(0);
  transform: translateX(100%) translateY(0) translateZ(0);
}
@media screen and (max-width: 991px) {
  #sidebar {
    width: 100%;
    opacity: 0;
  }
  .sidebar-o-xs #sidebar {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0) translateZ(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0) translateZ(0);
  }
}
@media screen and (min-width: 992px) {
  #sidebar {
    width: 230px;
    -webkit-transition: none;
    transition: none;
  }
  .sidebar-o #sidebar {
    -webkit-transform: translateX(0) translateY(0) translateZ(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0) translateZ(0);
  }
  .sidebar-o.sidebar-mini #sidebar {
    overflow-x: hidden;
    -webkit-transition: all 0.28s ease-out;
    transition: all 0.28s ease-out;
    will-change: transform;
  }
  .sidebar-l.sidebar-o.sidebar-mini #sidebar {
    -webkit-transform: translateX(-170px) translateY(0) translateZ(0);
    -ms-transform: translateX(-170px) translateY(0);
    transform: translateX(-170px) translateY(0) translateZ(0);
  }
  .sidebar-r.sidebar-o.sidebar-mini #sidebar {
    -webkit-transform: translateX(170px) translateY(0) translateZ(0);
    -ms-transform: translateX(170px) translateY(0);
    transform: translateX(170px) translateY(0) translateZ(0);
  }
  .sidebar-o.sidebar-mini #sidebar .sidebar-content {
    width: 230px;
    -webkit-transition: all 0.28s ease-out;
    transition: all 0.28s ease-out;
    will-change: transform;
  }
  .sidebar-l.sidebar-o.sidebar-mini #sidebar .sidebar-content {
    -webkit-transform: translateX(170px) translateY(0) translateZ(0);
    -ms-transform: translateX(170px) translateY(0);
    transform: translateX(170px) translateY(0) translateZ(0);
  }
  .sidebar-o.sidebar-mini #sidebar:hover,
  .sidebar-o.sidebar-mini #sidebar:hover .sidebar-content {
    -webkit-transform: translateX(0) translateY(0) translateZ(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0) translateZ(0);
  }
  .sidebar-o.sidebar-mini #sidebar .sidebar-mini-hide {
    opacity: 0;
    -webkit-transition: opacity 0.28s ease-out;
    transition: opacity 0.28s ease-out;
  }
  .sidebar-o.sidebar-mini #sidebar .sidebar-mini-hidden {
    display: none;
  }
  .sidebar-o.sidebar-mini #sidebar .nav-main > li.open > ul {
    display: none;
  }
  .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-hide {
    opacity: 1;
  }
  .sidebar-o.sidebar-mini #sidebar:hover .nav-main > li.open > ul {
    display: block;
  }
}
#side-overlay {
  background-color: #fff;
}
.sidebar-l #side-overlay {
  right: 0;
  -webkit-transform: translateX(100%) translateY(0) translateZ(0);
  -ms-transform: translateX(100%) translateY(0);
  transform: translateX(100%) translateY(0) translateZ(0);
}
.sidebar-r #side-overlay {
  left: 0;
  -webkit-transform: translateX(-100%) translateY(0) translateZ(0);
  -ms-transform: translateX(-100%) translateY(0);
  transform: translateX(-100%) translateY(0) translateZ(0);
}
@media screen and (max-width: 991px) {
  #side-overlay {
    width: 100%;
    opacity: 0;
  }
  .side-overlay-o #side-overlay {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0) translateZ(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0) translateZ(0);
  }
}
@media screen and (min-width: 992px) {
  #side-overlay {
    width: 320px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  .sidebar-l #side-overlay {
    -webkit-transform: translateX(110%) translateY(0) translateZ(0);
    -ms-transform: translateX(110%) translateY(0);
    transform: translateX(110%) translateY(0) translateZ(0);
  }
  .sidebar-r #side-overlay {
    -webkit-transform: translateX(-110%) translateY(0) translateZ(0);
    -ms-transform: translateX(-110%) translateY(0);
    transform: translateX(-110%) translateY(0) translateZ(0);
  }
  .sidebar-l.side-overlay-hover #side-overlay {
    -webkit-transform: translateX(300px) translateY(0) translateZ(0);
    -ms-transform: translateX(300px) translateY(0);
    transform: translateX(300px) translateY(0) translateZ(0);
  }
  .sidebar-r.side-overlay-hover #side-overlay {
    -webkit-transform: translateX(-300px) translateY(0) translateZ(0);
    -ms-transform: translateX(-300px) translateY(0);
    transform: translateX(-300px) translateY(0) translateZ(0);
  }
  .side-overlay-hover #side-overlay:hover,
  .side-overlay-o #side-overlay,
  .side-overlay-o.side-overlay-hover #side-overlay {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    -webkit-transform: translateX(0) translateY(0) translateZ(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0) translateZ(0);
  }
}
.side-header {
  margin: 0 auto;
  min-height: 60px;
}
.side-header:before,
.side-header:after {
  content: " ";
  display: table;
}
.side-header:after {
  clear: both;
}
.side-header.side-content {
  overflow: visible;
}
.side-header > span,
.side-header > a {
  display: inline-block;
  line-height: 34px;
}
.side-header img {
  display: inline-block;
  margin-top: -2px;
}
.side-content {
  margin: 0 auto;
  padding: 13px 20px 1px;
  max-width: 100%;
  overflow-x: hidden;
}
.side-content p,
.side-content .push,
.side-content .block,
.side-content .items-push > div {
  margin-bottom: 13px;
}
.side-content .items-push-2x > div {
  margin-bottom: 26px;
}
.side-content .items-push-3x > div {
  margin-bottom: 39px;
}
.side-content.side-content-full {
  padding-bottom: 13px;
}
.side-content.side-content-full .pull-b {
  margin-bottom: -13px;
}
.side-content .pull-t {
  margin-top: -13px;
}
.side-content .pull-r-l {
  margin-right: -20px;
  margin-left: -20px;
}
.side-content .pull-b {
  margin-bottom: -1px;
}
#main-container,
#page-footer {
  overflow-x: hidden;
}
#main-container {
  background-color: #f5f5f5;
  /*padding-left: 15px;*/
  /*padding-right:15px;*/
}
.content {
  margin: 0 auto;
  padding: 16px 14px 1px;
  max-width: 100%;
  overflow-x: visible;
}
.content p,
.content .push,
.content .block,
.content .items-push > div {
  margin-bottom: 16px;
}
.content .items-push-2x > div {
  margin-bottom: 32px;
}
.content .items-push-3x > div {
  margin-bottom: 48px;
}
.content.content-full {
  padding-bottom: 16px;
}
.content.content-full .pull-b {
  margin-bottom: -16px;
}
.content .pull-t {
  margin-top: -16px;
}
.content .pull-r-l {
  margin-right: -14px;
  margin-left: -14px;
}
.content .pull-b {
  margin-bottom: -1px;
}
@media screen and (min-width: 768px) {
  .content {
    margin: 0 auto;
    padding: 30px 30px 1px;
    max-width: 100%;
    overflow-x: visible;
  }
  .content p,
  .content .push,
  .content .block,
  .content .items-push > div {
    margin-bottom: 30px;
  }
  .content .items-push-2x > div {
    margin-bottom: 60px;
  }
  .content .items-push-3x > div {
    margin-bottom: 90px;
  }
  .content.content-full {
    padding-bottom: 30px;
  }
  .content.content-full .pull-b {
    margin-bottom: -30px;
  }
  .content .pull-t {
    margin-top: -30px;
  }
  .content .pull-r-l {
    margin-right: -30px;
    margin-left: -30px;
  }
  .content .pull-b {
    margin-bottom: -1px;
  }
  .content.content-boxed {
    max-width: 1280px;
  }
  .content.content-narrow {
    max-width: 95%;
  }
}
.content-grid {
  margin-bottom: 24px;
}
.content-grid .push,
.content-grid .block {
  margin-bottom: 6px;
}
.content-grid .row {
  margin-left: -3px;
  margin-right: -3px;
}
.content-grid .row > div[class*="col"] {
  padding-left: 3px;
  padding-right: 3px;
}
.content-mini {
  margin: 0 auto;
  padding: 13px 14px 1px;
  max-width: 100%;
  overflow-x: visible;
}
.content-mini p,
.content-mini .push,
.content-mini .block,
.content-mini .items-push > div {
  margin-bottom: 13px;
}
.content-mini .items-push-2x > div {
  margin-bottom: 26px;
}
.content-mini .items-push-3x > div {
  margin-bottom: 39px;
}
.content-mini.content-mini-full {
  padding-bottom: 13px;
}
.content-mini.content-mini-full .pull-b {
  margin-bottom: -13px;
}
.content-mini .pull-t {
  margin-top: -13px;
}
.content-mini .pull-r-l {
  margin-right: -14px;
  margin-left: -14px;
}
.content-mini .pull-b {
  margin-bottom: -1px;
}
@media screen and (min-width: 768px) {
  .content-mini {
    margin: 0 auto;
    padding: 13px 30px 1px;
    max-width: 100%;
    overflow-x: visible;
  }
  .content-mini p,
  .content-mini .push,
  .content-mini .block,
  .content-mini .items-push > div {
    margin-bottom: 13px;
  }
  .content-mini .items-push-2x > div {
    margin-bottom: 26px;
  }
  .content-mini .items-push-3x > div {
    margin-bottom: 39px;
  }
  .content-mini.content-mini-full {
    padding-bottom: 13px;
  }
  .content-mini.content-mini-full .pull-b {
    margin-bottom: -13px;
  }
  .content-mini .pull-t {
    margin-top: -13px;
  }
  .content-mini .pull-r-l {
    margin-right: -30px;
    margin-left: -30px;
  }
  .content-mini .pull-b {
    margin-bottom: -1px;
  }
}
.content-boxed {
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
}
.bg-image {
  background-color: #f9f9f9;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}
.bg-image-cover {
  height: 300px;
}
@media screen and (min-width: 992px) {
  .bg-image-cover {
    height: 750px;
  }
}
@media screen and (min-width: 1200px) {
  .bg-image-parallax {
    background-attachment: fixed;
  }
}
.form-material {
  position: relative;
  margin: 10px 0 10px;
}
.form-material > label {
  position: absolute;
  top: 6px;
  left: 0;
  font-size: 13px;
  font-weight: 600;
  -webkit-transform: translateY(-24px);
  -ms-transform: translateY(-24px);
  transform: translateY(-24px);
}
.form-material.floating > label {
  font-size: 15px;
  font-weight: 400;
  cursor: text;
  z-index: 10;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.form-material.floating > .form-control[disabled] + label,
.form-material.floating > .form-control[readonly] + label,
fieldset[disabled] .form-material.floating > label {
  cursor: not-allowed;
}
.form-material > .form-control {
  padding-left: 0;
  padding-right: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  -webkit-box-shadow: 0 1px 0 #e6e6e6;
  box-shadow: 0 1px 0 #e6e6e6;
}
.form-material > .form-control:focus {
  background-color: transparent;
  -webkit-box-shadow: 0 2px 0 #646464;
  box-shadow: 0 2px 0 #646464;
}
.form-material > .form-control:focus + label {
  color: #646464;
}
.form-material > .form-control:focus ~ .input-group-addon {
  -webkit-box-shadow: 0 2px 0 #646464;
  box-shadow: 0 2px 0 #646464;
}
.form-material > .form-control:focus + label,
.form-material.floating.open > label {
  font-size: 13px;
  font-weight: 600;
  cursor: default;
  -webkit-transform: translateY(-24px);
  -ms-transform: translateY(-24px);
  transform: translateY(-24px);
}
.form-material .form-control[disabled],
.form-material .form-control[readonly],
.form-material fieldset[disabled] .form-control {
  background-color: #fff;
  border-bottom: 1px dashed #ccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-material.input-group .input-group-addon {
  border: none;
  background-color: transparent;
  border-radius: 0 !important;
  -webkit-box-shadow: 0 1px 0 #e6e6e6;
  box-shadow: 0 1px 0 #e6e6e6;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.form-material.form-material-primary > .form-control:focus {
  -webkit-box-shadow: 0 2px 0 #5c90d2;
  box-shadow: 0 2px 0 #5c90d2;
}
.form-material.form-material-primary > .form-control:focus + label {
  color: #5c90d2;
}
.form-material.form-material-primary > .form-control:focus ~ .input-group-addon {
  color: #5c90d2;
  -webkit-box-shadow: 0 2px 0 #5c90d2;
  box-shadow: 0 2px 0 #5c90d2;
}
.form-material.form-material-success > .form-control:focus {
  -webkit-box-shadow: 0 2px 0 #46c37b;
  box-shadow: 0 2px 0 #46c37b;
}
.form-material.form-material-success > .form-control:focus + label {
  color: #46c37b;
}
.form-material.form-material-success > .form-control:focus ~ .input-group-addon {
  color: #46c37b;
  -webkit-box-shadow: 0 2px 0 #46c37b;
  box-shadow: 0 2px 0 #46c37b;
}
.form-material.form-material-info > .form-control:focus {
  -webkit-box-shadow: 0 2px 0 #70b9eb;
  box-shadow: 0 2px 0 #70b9eb;
}
.form-material.form-material-info > .form-control:focus + label {
  color: #70b9eb;
}
.form-material.form-material-info > .form-control:focus ~ .input-group-addon {
  color: #70b9eb;
  -webkit-box-shadow: 0 2px 0 #70b9eb;
  box-shadow: 0 2px 0 #70b9eb;
}
.form-material.form-material-warning > .form-control:focus {
  -webkit-box-shadow: 0 2px 0 #f3b760;
  box-shadow: 0 2px 0 #f3b760;
}
.form-material.form-material-warning > .form-control:focus + label {
  color: #f3b760;
}
.form-material.form-material-warning > .form-control:focus ~ .input-group-addon {
  color: #f3b760;
  -webkit-box-shadow: 0 2px 0 #f3b760;
  box-shadow: 0 2px 0 #f3b760;
}
.form-material.form-material-danger > .form-control:focus {
  -webkit-box-shadow: 0 2px 0 #d26a5c;
  box-shadow: 0 2px 0 #d26a5c;
}
.form-material.form-material-danger > .form-control:focus + label {
  color: #d26a5c;
}
.form-material.form-material-danger > .form-control:focus ~ .input-group-addon {
  color: #d26a5c;
  -webkit-box-shadow: 0 2px 0 #d26a5c;
  box-shadow: 0 2px 0 #d26a5c;
}
.has-success .form-material > .form-control {
  -webkit-box-shadow: 0 1px 0 #46c37b;
  box-shadow: 0 1px 0 #46c37b;
}
.has-success .form-material > .form-control:focus {
  -webkit-box-shadow: 0 2px 0 #46c37b;
  box-shadow: 0 2px 0 #46c37b;
}
.has-success .form-material > .form-control:focus + label {
  color: #46c37b;
}
.has-success .form-material > .form-control:focus ~ .input-group-addon {
  color: #46c37b;
  -webkit-box-shadow: 0 2px 0 #46c37b;
  box-shadow: 0 2px 0 #46c37b;
}
.has-success .form-material > .form-control ~ .input-group-addon {
  color: #46c37b;
  -webkit-box-shadow: 0 1px 0 #46c37b;
  box-shadow: 0 1px 0 #46c37b;
}
.has-success .form-material label {
  color: #46c37b;
}
.has-success .form-material > .help-block {
  color: #46c37b;
}
.has-info .form-material > .form-control {
  -webkit-box-shadow: 0 1px 0 #70b9eb;
  box-shadow: 0 1px 0 #70b9eb;
}
.has-info .form-material > .form-control:focus {
  -webkit-box-shadow: 0 2px 0 #70b9eb;
  box-shadow: 0 2px 0 #70b9eb;
}
.has-info .form-material > .form-control:focus + label {
  color: #70b9eb;
}
.has-info .form-material > .form-control:focus ~ .input-group-addon {
  color: #70b9eb;
  -webkit-box-shadow: 0 2px 0 #70b9eb;
  box-shadow: 0 2px 0 #70b9eb;
}
.has-info .form-material > .form-control ~ .input-group-addon {
  color: #70b9eb;
  -webkit-box-shadow: 0 1px 0 #70b9eb;
  box-shadow: 0 1px 0 #70b9eb;
}
.has-info .form-material label {
  color: #70b9eb;
}
.has-info .form-material > .help-block {
  color: #70b9eb;
}
.has-warning .form-material > .form-control {
  -webkit-box-shadow: 0 1px 0 #f3b760;
  box-shadow: 0 1px 0 #f3b760;
}
.has-warning .form-material > .form-control:focus {
  -webkit-box-shadow: 0 2px 0 #f3b760;
  box-shadow: 0 2px 0 #f3b760;
}
.has-warning .form-material > .form-control:focus + label {
  color: #f3b760;
}
.has-warning .form-material > .form-control:focus ~ .input-group-addon {
  color: #f3b760;
  -webkit-box-shadow: 0 2px 0 #f3b760;
  box-shadow: 0 2px 0 #f3b760;
}
.has-warning .form-material > .form-control ~ .input-group-addon {
  color: #f3b760;
  -webkit-box-shadow: 0 1px 0 #f3b760;
  box-shadow: 0 1px 0 #f3b760;
}
.has-warning .form-material label {
  color: #f3b760;
}
.has-warning .form-material > .help-block {
  color: #f3b760;
}
.has-error .form-material > .form-control {
  -webkit-box-shadow: 0 1px 0 #d26a5c;
  box-shadow: 0 1px 0 #d26a5c;
}
.has-error .form-material > .form-control:focus {
  -webkit-box-shadow: 0 2px 0 #d26a5c;
  box-shadow: 0 2px 0 #d26a5c;
}
.has-error .form-material > .form-control:focus + label {
  color: #d26a5c;
}
.has-error .form-material > .form-control:focus ~ .input-group-addon {
  color: #d26a5c;
  -webkit-box-shadow: 0 2px 0 #d26a5c;
  box-shadow: 0 2px 0 #d26a5c;
}
.has-error .form-material > .form-control ~ .input-group-addon {
  color: #d26a5c;
  -webkit-box-shadow: 0 1px 0 #d26a5c;
  box-shadow: 0 1px 0 #d26a5c;
}
.has-error .form-material label {
  color: #d26a5c;
}
.has-error .form-material > .help-block {
  color: #d26a5c;
}
.css-input {
  position: relative;
  display: inline-block;
  margin: 2px 0;
  font-weight: 400;
  cursor: pointer;
}
.css-input input {
  position: absolute;
  opacity: 0;
}
.css-input input:focus + span {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}
.css-input input + span {
  position: relative;
  display: inline-block;
  margin-top: -2px;
  margin-right: 3px;
  vertical-align: middle;
}
.css-input input + span:after {
  position: absolute;
  content: "";
}
.css-input-disabled {
  opacity: .5;
  cursor: not-allowed;
}
.css-checkbox {
  margin: 7px 0;
}
.css-checkbox input + span {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.css-checkbox input + span:after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: "FontAwesome";
  font-size: 10px;
  color: #fff;
  line-height: 18px;
  content: "\f00c";
  text-align: center;
}
.css-checkbox:hover input + span {
  border-color: #ccc;
}
.css-checkbox.css-checkbox-sm {
  margin: 9px 0 8px;
  font-size: 12px;
}
.css-checkbox.css-checkbox-sm input + span {
  width: 16px;
  height: 16px;
}
.css-checkbox.css-checkbox-sm input + span:after {
  font-size: 8px;
  line-height: 15px;
}
.css-checkbox.css-checkbox-lg {
  margin: 3px 0;
}
.css-checkbox.css-checkbox-lg input + span {
  width: 30px;
  height: 30px;
}
.css-checkbox.css-checkbox-lg input + span:after {
  font-size: 12px;
  line-height: 30px;
}
.css-checkbox.css-checkbox-rounded input + span {
  border-radius: 3px;
}
.css-checkbox-default input:checked + span {
  background-color: #999999;
  border-color: #999999;
}
.css-checkbox-primary input:checked + span {
  background-color: #5c90d2;
  border-color: #5c90d2;
}
.css-checkbox-info input:checked + span {
  background-color: #70b9eb;
  border-color: #70b9eb;
}
.css-checkbox-success input:checked + span {
  background-color: #46c37b;
  border-color: #46c37b;
}
.css-checkbox-warning input:checked + span {
  background-color: #f3b760;
  border-color: #f3b760;
}
.css-checkbox-danger input:checked + span {
  background-color: #d26a5c;
  border-color: #d26a5c;
}
.css-radio {
  margin: 7px 0;
}
.css-radio input + span {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
}
.css-radio input + span:after {
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}
.css-radio input:checked + span:after {
  opacity: 1;
}
.css-radio:hover input + span {
  border-color: #ccc;
}
.css-radio.css-radio-sm {
  margin: 9px 0 8px;
  font-size: 12px;
}
.css-radio.css-radio-sm input + span {
  width: 16px;
  height: 16px;
}
.css-radio.css-radio-lg {
  margin: 5px 0;
}
.css-radio.css-radio-lg input + span {
  width: 26px;
  height: 26px;
}
.css-radio-default input:checked + span:after {
  background-color: #999999;
}
.css-radio-primary input:checked + span:after {
  background-color: #5c90d2;
}
.css-radio-info input:checked + span:after {
  background-color: #70b9eb;
}
.css-radio-success input:checked + span:after {
  background-color: #46c37b;
}
.css-radio-warning input:checked + span:after {
  background-color: #f3b760;
}
.css-radio-danger input:checked + span:after {
  background-color: #d26a5c;
}
.switch {
  margin: 3px 0;
}
.switch input + span {
  width: 54px;
  height: 30px;
  background-color: #eee;
  border-radius: 30px;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}
.switch input + span:after {
  top: 2px;
  bottom: 2px;
  left: 2px;
  width: 26px;
  background-color: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
}
.switch input:checked + span {
  background-color: #ddd;
}
.switch input:checked + span:after {
  -webkit-box-shadow: -2px 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 0 3px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}
.switch.switch-sm {
  margin: 8px 0 7px;
  font-size: 12px;
}
.switch.switch-sm input + span {
  width: 36px;
  height: 20px;
}
.switch.switch-sm input + span:after {
  width: 16px;
}
.switch.switch-sm input:checked + span:after {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}
.switch.switch-lg {
  margin: 1px 0;
}
.switch.switch-lg input + span {
  width: 70px;
  height: 34px;
}
.switch.switch-lg input + span:after {
  width: 30px;
}
.switch.switch-lg input:checked + span:after {
  -webkit-transform: translateX(35px);
  -ms-transform: translateX(35px);
  transform: translateX(35px);
}
.switch.switch-square input + span,
.switch.switch-square input + span:after {
  border-radius: 0;
}
.switch-default input:checked + span {
  background-color: #999999;
}
.switch-primary input:checked + span {
  background-color: #5c90d2;
}
.switch-info input:checked + span {
  background-color: #70b9eb;
}
.switch-success input:checked + span {
  background-color: #46c37b;
}
.switch-warning input:checked + span {
  background-color: #f3b760;
}
.switch-danger input:checked + span {
  background-color: #d26a5c;
}
.block {
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px rgba(0, 0, 0, 0.01);
  box-shadow: 0 2px rgba(0, 0, 0, 0.01);
}
.block .block,
.side-content .block {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.block-header {
  padding: 15px 20px;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}
.block-header:before,
.block-header:after {
  content: " ";
  display: table;
}
.block-header:after {
  clear: both;
}
.block-title {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2;
}
.block-title.text-normal {
  text-transform: none;
}
.block-title small {
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
}
.block-content {
  margin: 0 auto;
  padding: 20px 20px 1px;
  max-width: 100%;
  overflow-x: visible;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}
.block-content p,
.block-content .push,
.block-content .block,
.block-content .items-push > div {
  margin-bottom: 20px;
}
.block-content .items-push-2x > div {
  margin-bottom: 40px;
}
.block-content .items-push-3x > div {
  margin-bottom: 60px;
}
.block-content.block-content-full {
  padding-bottom: 20px;
}
.block-content.block-content-full .pull-b {
  margin-bottom: -20px;
}
.block-content .pull-t {
  margin-top: -20px;
}
.block-content .pull-r-l {
  margin-right: -20px;
  margin-left: -20px;
}
.block-content .pull-b {
  margin-bottom: -1px;
}
.block-content.block-content-mini {
  padding-top: 10px;
}
.block-content.block-content-mini.block-content-full.block-content-mini {
  padding-bottom: 10px;
}
@media screen and (min-width: 1200px) {
  .block-content.block-content-narrow {
    padding-left: 10%;
    padding-right: 10%;
  }
}
.block.block-full .block-content {
  padding-bottom: 20px;
}
.block.block-full .block-content.block-content-mini {
  padding-bottom: 10px;
}
.block-table {
  width: 100%;
}
.block-table td {
  padding: 10px;
  vertical-align: middle;
}
.block.block-bordered {
  border: 1px solid #e9e9e9;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.block.block-bordered > .block-header {
  border-bottom: 1px solid #e9e9e9;
}
.block.block-rounded {
  border-radius: 4px;
}
.block.block-rounded > .block-header {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.block.block-rounded > .block-content:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.block.block-rounded > .block-content:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.block.block-themed > .block-header {
  border-bottom: none;
}
.block.block-themed > .block-header > .block-title {
  color: #fff;
}
.block.block-themed > .block-header > .block-title small {
  color: rgba(255, 255, 255, 0.75);
}
.block.block-transparent {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.block.block-opt-refresh {
  position: relative;
}
.block.block-opt-refresh > .block-header {
  opacity: .25;
}
.block.block-opt-refresh > .block-content {
  opacity: .15;
}
.block.block-opt-refresh:after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #646464;
  font-family: Simple-Line-Icons;
  font-size: 18px;
  text-align: center;
  z-index: 2;
  content: "\e09a";
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.block.block-opt-refresh.block-opt-refresh-icon2:after {
  content: "\e06e";
}
.block.block-opt-refresh.block-opt-refresh-icon3:after {
  content: "\e020";
}
.block.block-opt-refresh.block-opt-refresh-icon4:after {
  font-family: 'FontAwesome';
  content: "\f021";
}
.block.block-opt-refresh.block-opt-refresh-icon5:after {
  font-family: 'FontAwesome';
  content: "\f185";
}
.block.block-opt-refresh.block-opt-refresh-icon6:after {
  font-family: 'FontAwesome';
  content: "\f1ce";
}
.block.block-opt-refresh.block-opt-refresh-icon7:after {
  font-family: 'FontAwesome';
  content: "\f250";
}
.block.block-opt-refresh.block-opt-refresh-icon8:after {
  font-family: 'FontAwesome';
  content: "\f01e";
}
.ie9 .block.block-opt-refresh:after {
  content: "Loading.." !important;
}
.block.block-opt-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  margin-bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.block.block-opt-hidden.block-bordered > .block-header {
  border-bottom: none;
}
.block.block-opt-hidden > .block-content {
  display: none;
}
a.block {
  display: block;
  color: #646464;
  font-weight: normal;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
a.block:hover {
  color: #646464;
  opacity: .9;
}
a.block.block-link-hover1:hover {
  -webkit-box-shadow: 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
  opacity: 1;
}
a.block.block-link-hover1:active {
  -webkit-box-shadow: 0 2px rgba(0, 0, 0, 0.01);
  box-shadow: 0 2px rgba(0, 0, 0, 0.01);
}
a.block.block-link-hover2:hover {
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  opacity: 1;
}
a.block.block-link-hover2:active {
  -webkit-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
}
a.block.block-link-hover3:hover {
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  opacity: 1;
}
a.block.block-link-hover3:active {
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}
.block > .nav-tabs {
  background-color: #f9f9f9;
  border-bottom: none;
}
.block > .nav-tabs.nav-tabs-right > li {
  float: right;
}
.block > .nav-tabs.nav-justified > li > a {
  margin-bottom: 0;
}
.block > .nav-tabs > li {
  margin-bottom: 0;
}
.block > .nav-tabs > li > a {
  margin-right: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #646464;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: 0;
}
.block > .nav-tabs > li > a:hover {
  color: #5c90d2;
  background-color: transparent;
  border-color: transparent;
}
.block > .nav-tabs > li.active > a,
.block > .nav-tabs > li.active > a:hover,
.block > .nav-tabs > li.active > a:focus {
  color: #646464;
  background-color: #fff;
  border-color: transparent;
}
.block > .nav-tabs.nav-tabs-alt {
  background-color: transparent;
  border-bottom: 1px solid #e9e9e9;
}
.block > .nav-tabs.nav-tabs-alt > li > a {
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.block > .nav-tabs.nav-tabs-alt > li > a:hover {
  -webkit-box-shadow: 0 2px #5c90d2;
  box-shadow: 0 2px #5c90d2;
}
.block > .nav-tabs.nav-tabs-alt > li.active > a,
.block > .nav-tabs.nav-tabs-alt > li.active > a:hover,
.block > .nav-tabs.nav-tabs-alt > li.active > a:focus {
  -webkit-box-shadow: 0 2px #5c90d2;
  box-shadow: 0 2px #5c90d2;
}
.block .block-content.tab-content {
  overflow: hidden;
}
.block-options-simple {
  float: right;
  margin: -3px 0 -3px 15px;
  padding: 1px 0;
  min-height: 24px;
}
.block-options-simple.block-options-simple-left {
  float: left;
  margin-right: 15px;
  margin-left: 0;
}
.block-options-simple.block-options-simple-left + .block-title {
  float: right;
}
.block-options {
  float: right;
  margin: -3px 0 -3px 15px;
  padding: 0;
  height: 24px;
  list-style: none;
}
.block-options:before,
.block-options:after {
  content: " ";
  display: table;
}
.block-options:after {
  clear: both;
}
.block-options.block-options-left {
  float: left;
  margin-right: 15px;
  margin-left: 0;
}
.block-options.block-options-left + .block-title {
  float: right;
}
.block-options > li {
  display: inline-block;
  margin: 0 2px;
  padding: 0;
}
.block-options > li > a,
.block-options > li > button {
  display: block;
  padding: 2px 3px;
  color: #999999;
  opacity: .6;
}
.block.block-themed > .block-header .block-options > li > a,
.block.block-themed > .block-header .block-options > li > button {
  color: #fff;
}
.block-options > li > a:hover,
.block-options > li > button:hover {
  text-decoration: none;
  opacity: 1;
}
.block-options > li > a:active,
.block-options > li > button:active {
  opacity: .6;
}
.block-options > li > span {
  display: block;
  padding: 2px 3px;
}
.block.block-themed > .block-header .block-options > li > span {
  color: #fff;
}
.block-options > li > a:focus {
  text-decoration: none;
  opacity: 1;
}
.block-options > li > button {
  background: none;
  border: none;
}
.block-options > li.active > a,
.block-options > li.open > button {
  text-decoration: none;
  opacity: 1;
}
.nav-main {
  margin: 0 -20px;
  padding: 0;
  list-style: none;
}
.nav-main .nav-main-heading {
  padding: 22px 20px 6px 20px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
}
.nav-main a {
  display: block;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.5);
}
.nav-main a:hover,
.nav-main a:focus {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.2);
}
.nav-main a:hover > i,
.nav-main a:focus > i {
  color: #fff;
}
.nav-main a.active,
.nav-main a.active:hover {
  color: #fff;
}
.nav-main a.active > i,
.nav-main a.active:hover > i {
  color: #fff;
}
.nav-main a > i {
  margin-right: 15px;
  color: rgba(255, 255, 255, 0.2);
}
.nav-main a.nav-submenu {
  position: relative;
  padding-right: 30px;
}
.nav-main a.nav-submenu:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  display: inline-block;
  font-family: 'FontAwesome';
  color: rgba(255, 255, 255, 0.25);
  content: "\f104";
}
.nav-main a.nav-submenu:before.nav-main a.nav-submenu:before-fwidth {
  width: 100%;
}
.nav-main ul {
  margin: 0;
  padding: 0 0 0 50px;
  height: 0;
  list-style: none;
  background-color: rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.nav-main ul > li {
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-transform: translateX(-15px);
  -ms-transform: translateX(-15px);
  transform: translateX(-15px);
}
.nav-main ul .nav-main-heading {
  padding-left: 0;
  padding-right: 0;
  color: rgba(255, 255, 255, 0.65);
}
.nav-main ul a {
  padding: 8px 8px 8px 0;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.4);
}
.nav-main ul a:hover,
.nav-main ul a:focus {
  color: #fff;
  background-color: transparent;
}
.nav-main ul a > i {
  margin-right: 10px;
}
.nav-main ul ul {
  padding-left: 12px;
}
.nav-main li.open > a.nav-submenu {
  color: #fff;
}
.nav-main li.open > a.nav-submenu > i {
  color: #fff;
}
.nav-main li.open > a.nav-submenu:before {
  content: "\f107";
}
.nav-main li.open > ul {
  height: auto;
}
.nav-main li.open > ul > li {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.nav-main-header {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 20px;
  width: 100%;
  list-style: none;
  background-color: #2c343f;
  z-index: 1031;
  opacity: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 0.28s ease-out;
  transition: all 0.28s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(0) translateY(-100%) translateZ(0);
  -ms-transform: translateX(0) translateY(-100%);
  transform: translateX(0) translateY(-100%) translateZ(0);
}
.nav-main-header.nav-main-header-o {
  -webkit-transform: translateX(0) translateY(0) translateZ(0);
  -ms-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0) translateZ(0);
  opacity: 1;
}
.nav-main-header > li {
  margin: 0 0 10px;
}
.nav-main-header a {
  display: block;
  padding: 0 12px;
  min-height: 34px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  line-height: 34px;
}
.nav-main-header a:hover,
.nav-main-header a:focus,
.nav-main-header a.active {
  color: #fff;
}
.nav-main-header a.nav-submenu {
  position: relative;
  padding-right: 32px;
}
.nav-main-header a.nav-submenu:before {
  position: absolute;
  right: 10px;
  font-family: 'FontAwesome';
  content: "\f107";
}
.nav-main-header ul {
  margin: 0 0 0 15px;
  padding: 0;
  list-style: none;
  display: none;
}
.nav-main-header ul a {
  min-height: 32px;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
}
.nav-main-header > li:hover > a.nav-submenu {
  color: #fff;
}
.nav-main-header > li:hover > ul {
  display: block;
}
@media screen and (min-width: 992px) {
  .nav-main-header {
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding: 0;
    width: auto;
    background-color: transparent;
    z-index: auto;
    opacity: 1;
    overflow-y: visible;
    -webkit-overflow-scrolling: auto;
    -webkit-transition: none;
    transition: none;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-transform: translateX(0) translateY(0) translateZ(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0) translateZ(0);
  }
  .nav-main-header > li {
    position: relative;
    margin: 0 10px 0 0;
    float: left;
  }
  .nav-main-header ul {
    position: absolute;
    left: 0;
    margin: 0;
    padding: 13px 0;
    min-width: 160px;
    background-color: #3e4a59;
  }
  .nav-main-header > li:last-child > ul {
    left: auto;
    right: 0;
  }
}
.nav-header {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-header:before,
.nav-header:after {
  content: " ";
  display: table;
}
.nav-header:after {
  clear: both;
}
.nav-header > li {
  margin-right: 12px;
  float: left;
}
.nav-header > li > a,
.nav-header > li > .btn-group > a {
  padding: 0 12px;
  display: block;
  height: 34px;
  line-height: 34px;
  font-weight: 600;
}
.nav-header.pull-right > li {
  margin-right: 0;
  margin-left: 12px;
  float: left;
}
.nav-header .header-content {
  line-height: 34px;
}
.nav-header .header-search {
  width: 360px;
}
@media screen and (max-width: 767px) {
  .nav-header .header-search {
    display: none;
  }
  .nav-header .header-search.header-search-xs-visible {
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    z-index: 999;
    display: block;
    width: 100%;
    border-top: 1px solid #f9f9f9;
  }
  .nav-header .header-search.header-search-xs-visible > form {
    padding: 14px 14px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
  }
}
.nav-users {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-users > li:last-child > a {
  border-bottom: none;
}
.nav-users a {
  position: relative;
  padding: 12px 8px 8px 71px;
  display: block;
  min-height: 62px;
  font-weight: 600;
  border-bottom: 1px solid #f3f3f3;
}
.nav-users a > img {
  position: absolute;
  left: 12px;
  top: 10px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.nav-users a > i {
  position: absolute;
  left: 40px;
  top: 40px;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
}
.nav-users a:hover {
  background-color: #f9f9f9;
}
.list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list > li {
  position: relative;
}
.list-timeline {
  position: relative;
  padding-top: 10px;
}
.list-timeline > li {
  margin-bottom: 10px;
}
.list-timeline .list-timeline-time {
  margin: 0 -20px;
  padding: 10px 20px 10px 40px;
  min-height: 40px;
  text-align: right;
  color: #999;
  font-size: 13px;
  font-style: italic;
  background-color: #f9f9f9;
  border-radius: 2px;
}
.list-timeline .list-timeline-icon {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}
.list-timeline .list-timeline-content {
  padding: 10px 10px 1px;
}
.list-timeline .list-timeline-content > p:first-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .list-timeline {
    padding-top: 20px;
  }
  .list-timeline:before {
    position: absolute;
    top: 0;
    left: 120px;
    bottom: 0;
    display: block;
    width: 4px;
    content: "";
    background-color: #f9f9f9;
    z-index: 1;
  }
  .list-timeline > li {
    min-height: 40px;
    z-index: 2;
  }
  .list-timeline > li:last-child {
    margin-bottom: 0;
  }
  .list-timeline .list-timeline-time {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding-right: 0;
    padding-left: 0;
    width: 90px;
    background-color: transparent;
  }
  .list-timeline .list-timeline-icon {
    top: 3px;
    left: 105px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    z-index: 2 !important;
  }
  .list-timeline .list-timeline-content {
    padding-left: 160px;
  }
}
.list-activity > li {
  margin-bottom: 7px;
  padding-bottom: 7px;
  padding-left: 40px;
  font-size: 13px;
  border-bottom: 1px solid #f3f3f3;
}
.list-activity > li > i:first-child {
  position: absolute;
  left: 10px;
  top: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
}
.list-activity > li:last-child {
  border-bottom: none;
}
.list-events > li {
  margin-bottom: 5px;
  padding: 8px 30px 8px 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: 700;
  background-color: #b5d0eb;
}
.list-events > li:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  display: inline-block;
  font-family: 'FontAwesome';
  color: rgba(255, 255, 255, 0.75);
  content: "\f073";
}
.list-events > li:before.list-events > li:before-fwidth {
  width: 100%;
}
.list-events > li:hover {
  cursor: move;
}
.list-simple > li {
  margin-bottom: 20px;
}
.list-simple-mini > li {
  margin-bottom: 10px;
}
.list-li-clearfix > li:before,
.list-li-clearfix > li:after {
  content: " ";
  display: table;
}
.list-li-clearfix > li:after {
  clear: both;
}
.img-avatar {
  display: inline-block !important;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.img-avatar.img-avatar16 {
  width: 16px;
  height: 16px;
}
.img-avatar.img-avatar32 {
  width: 32px;
  height: 32px;
}
.img-avatar.img-avatar48 {
  width: 48px;
  height: 48px;
}
.img-avatar.img-avatar96 {
  width: 96px;
  height: 96px;
}
.img-avatar.img-avatar128 {
  width: 128px;
  height: 128px;
}
.img-avatar-thumb {
  margin: 5px;
  -webkit-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
}
.img-thumb {
  padding: 5px;
  background-color: #fff;
  border-radius: 2px;
}
.img-link {
  display: inline-block;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
}
.img-link:hover {
  -webkit-transform: rotate(1deg);
  -ms-transform: rotate(1deg);
  transform: rotate(1deg);
}
.img-container {
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: block;
}
.img-container .img-options {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: none;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}
.img-container .img-options-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  left: 0;
  text-align: center;
}
.img-container > img {
  -webkit-transition: -webkit-transform 0.35s ease-out;
  transition: transform 0.35s ease-out;
}
.img-container:hover .img-options {
  opacity: 1;
  visibility: visible;
}
.img-container.fx-img-zoom-in:hover > img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.img-container.fx-img-rotate-r:hover > img {
  -webkit-transform: scale(1.4) rotate(8deg);
  -ms-transform: scale(1.4) rotate(8deg);
  transform: scale(1.4) rotate(8deg);
}
.img-container.fx-img-rotate-l:hover > img {
  -webkit-transform: scale(1.4) rotate(-8deg);
  -ms-transform: scale(1.4) rotate(-8deg);
  transform: scale(1.4) rotate(-8deg);
}
.img-container.fx-opt-slide-top .img-options {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}
.img-container.fx-opt-slide-top:hover .img-options {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.img-container.fx-opt-slide-right .img-options {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
.img-container.fx-opt-slide-right:hover .img-options {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.img-container.fx-opt-slide-down .img-options {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
.img-container.fx-opt-slide-down:hover .img-options {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.img-container.fx-opt-slide-left .img-options {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}
.img-container.fx-opt-slide-left:hover .img-options {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.img-container.fx-opt-zoom-in .img-options {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.img-container.fx-opt-zoom-in:hover .img-options {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.img-container.fx-opt-zoom-out .img-options {
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
}
.img-container.fx-opt-zoom-out:hover .img-options {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.push-5 {
  margin-bottom: 5px !important;
}
.push-5-t {
  margin-top: 5px !important;
}
.push-5-r {
  margin-right: 5px !important;
}
.push-5-l {
  margin-left: 5px !important;
}
.push-10 {
  margin-bottom: 10px !important;
}
.push-10-t {
  margin-top: 10px !important;
}
.push-10-r {
  margin-right: 10px !important;
}
.push-10-l {
  margin-left: 10px !important;
}
.push-15 {
  margin-bottom: 15px !important;
}
.push-15-t {
  margin-top: 15px !important;
}
.push-15-r {
  margin-right: 15px !important;
}
.push-15-l {
  margin-left: 15px !important;
}
.push-20 {
  margin-bottom: 20px !important;
}
.push-20-t {
  margin-top: 20px !important;
}
.push-20-r {
  margin-right: 20px !important;
}
.push-20-l {
  margin-left: 20px !important;
}
.push-30 {
  margin-bottom: 30px !important;
}
.push-30-t {
  margin-top: 30px !important;
}
.push-30-r {
  margin-right: 30px !important;
}
.push-30-l {
  margin-left: 30px !important;
}
.push-50 {
  margin-bottom: 50px !important;
}
.push-50-t {
  margin-top: 50px !important;
}
.push-50-r {
  margin-right: 50px !important;
}
.push-50-l {
  margin-left: 50px !important;
}
.push-100 {
  margin-bottom: 100px !important;
}
.push-100-t {
  margin-top: 100px !important;
}
.push-100-r {
  margin-right: 100px !important;
}
.push-100-l {
  margin-left: 100px !important;
}
.push-150 {
  margin-bottom: 150px !important;
}
.push-150-t {
  margin-top: 150px !important;
}
.push-150-r {
  margin-right: 150px !important;
}
.push-150-l {
  margin-left: 150px !important;
}
.pulldown {
  position: relative;
  top: 50px;
}
@media screen and (min-width: 992px) {
  .pulldown {
    top: 150px;
  }
}
.remove-margin {
  margin: 0 !important;
}
.remove-margin-t {
  margin-top: 0 !important;
}
.remove-margin-r {
  margin-right: 0 !important;
}
.remove-margin-b {
  margin-bottom: 0 !important;
}
.remove-margin-l {
  margin-left: 0 !important;
}
.remove-padding {
  padding: 0 !important;
}
.remove-padding-t {
  padding-top: 0 !important;
}
.remove-padding-r {
  padding-right: 0 !important;
}
.remove-padding-b {
  padding-bottom: 0 !important;
}
.remove-padding-l {
  padding-left: 0 !important;
}
.mheight-50 {
  min-height: 50px;
}
.mheight-75 {
  min-height: 75px;
}
.mheight-100 {
  min-height: 100px;
}
.mheight-125 {
  min-height: 125px;
}
.mheight-150 {
  min-height: 150px;
}
.mheight-175 {
  min-height: 175px;
}
.mheight-200 {
  min-height: 200px;
}
.align-v {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.align-v.align-v-fwidth {
  width: 100%;
}
.border {
  border: 1px solid #e9e9e9;
}
.border-t {
  border-top: 1px solid #e9e9e9;
}
.border-r {
  border-right: 1px solid #e9e9e9;
}
.border-b {
  border-bottom: 1px solid #e9e9e9;
}
.border-l {
  border-left: 1px solid #e9e9e9;
}
.border-white-op {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.border-white-op-t {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.border-white-op-r {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.border-white-op-b {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.border-white-op-l {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.border-black-op {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.border-black-op-t {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.border-black-op-r {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.border-black-op-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.border-black-op-l {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.item {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 60px;
}
.item .si:before {
  position: relative;
  top: 1px;
}
a.item:hover,
a.item:focus {
  opacity: .6;
}
.item.item-circle {
  border-radius: 50%;
}
.item.item-rounded {
  border-radius: 3px;
}
.item.item-rounded-big {
  border-radius: 24px;
}
.item.item-rounded-big.item-2x {
  border-radius: 35px;
}
.item.item-2x {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.item.item-2x .si:before {
  position: relative;
  top: 3px;
}
.ribbon {
  position: relative;
  min-height: 56px;
}
.ribbon-box {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0 15px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  z-index: 500;
}
.ribbon-box:before {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  content: "";
}
.ribbon-bookmark .ribbon-box {
  padding-left: 0;
}
.ribbon-bookmark .ribbon-box:before {
  top: 0;
  right: 100%;
  border: 18px solid;
  border-left-width: 10px;
}
.ribbon-modern .ribbon-box {
  top: 0;
}
.ribbon-modern .ribbon-box:before {
  top: 0;
  right: 100%;
  border: 18px solid;
}
.ribbon-left .ribbon-box {
  right: auto;
  left: 0;
}
.ribbon-left.ribbon-bookmark .ribbon-box {
  padding-left: 15px;
  padding-right: 0;
}
.ribbon-left.ribbon-bookmark .ribbon-box:before {
  right: auto;
  left: 100%;
  border-left-width: 18px;
  border-right-width: 10px;
}
.ribbon-left.ribbon-modern .ribbon-box:before {
  right: auto;
  left: 100%;
}
.ribbon-bottom .ribbon-box {
  top: auto;
  bottom: 10px;
}
.ribbon-bottom.ribbon-modern .ribbon-box {
  bottom: 0;
}
.ribbon-primary .ribbon-box {
  background-color: #5c90d2;
}
.ribbon-primary.ribbon-bookmark .ribbon-box:before {
  border-color: #5c90d2;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box:before {
  border-color: #5c90d2;
  border-right-color: transparent;
}
.ribbon-primary.ribbon-modern .ribbon-box:before {
  border-color: #5c90d2;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-bottom .ribbon-box:before {
  border-color: #5c90d2;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-left .ribbon-box:before {
  border-color: #5c90d2;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box:before {
  border-color: #5c90d2;
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-success .ribbon-box {
  background-color: #46c37b;
}
.ribbon-success.ribbon-bookmark .ribbon-box:before {
  border-color: #46c37b;
  border-left-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-left .ribbon-box:before {
  border-color: #46c37b;
  border-right-color: transparent;
}
.ribbon-success.ribbon-modern .ribbon-box:before {
  border-color: #46c37b;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-success.ribbon-modern.ribbon-bottom .ribbon-box:before {
  border-color: #46c37b;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-success.ribbon-modern.ribbon-left .ribbon-box:before {
  border-color: #46c37b;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-success.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box:before {
  border-color: #46c37b;
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-info .ribbon-box {
  background-color: #70b9eb;
}
.ribbon-info.ribbon-bookmark .ribbon-box:before {
  border-color: #70b9eb;
  border-left-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-left .ribbon-box:before {
  border-color: #70b9eb;
  border-right-color: transparent;
}
.ribbon-info.ribbon-modern .ribbon-box:before {
  border-color: #70b9eb;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-info.ribbon-modern.ribbon-bottom .ribbon-box:before {
  border-color: #70b9eb;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-info.ribbon-modern.ribbon-left .ribbon-box:before {
  border-color: #70b9eb;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-info.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box:before {
  border-color: #70b9eb;
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-warning .ribbon-box {
  background-color: #f3b760;
}
.ribbon-warning.ribbon-bookmark .ribbon-box:before {
  border-color: #f3b760;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-left .ribbon-box:before {
  border-color: #f3b760;
  border-right-color: transparent;
}
.ribbon-warning.ribbon-modern .ribbon-box:before {
  border-color: #f3b760;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-warning.ribbon-modern.ribbon-bottom .ribbon-box:before {
  border-color: #f3b760;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-modern.ribbon-left .ribbon-box:before {
  border-color: #f3b760;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-warning.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box:before {
  border-color: #f3b760;
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-danger .ribbon-box {
  background-color: #d26a5c;
}
.ribbon-danger.ribbon-bookmark .ribbon-box:before {
  border-color: #d26a5c;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-left .ribbon-box:before {
  border-color: #d26a5c;
  border-right-color: transparent;
}
.ribbon-danger.ribbon-modern .ribbon-box:before {
  border-color: #d26a5c;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-danger.ribbon-modern.ribbon-bottom .ribbon-box:before {
  border-color: #d26a5c;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-modern.ribbon-left .ribbon-box:before {
  border-color: #d26a5c;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-danger.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box:before {
  border-color: #d26a5c;
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-crystal .ribbon-box {
  background-color: rgba(255, 255, 255, 0.35);
}
.ribbon-crystal.ribbon-bookmark .ribbon-box:before {
  border-color: rgba(255, 255, 255, 0.35);
  border-left-color: transparent;
}
.ribbon-crystal.ribbon-bookmark.ribbon-left .ribbon-box:before {
  border-color: rgba(255, 255, 255, 0.35);
  border-right-color: transparent;
}
.ribbon-crystal.ribbon-modern .ribbon-box:before {
  border-color: rgba(255, 255, 255, 0.35);
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-crystal.ribbon-modern.ribbon-bottom .ribbon-box:before {
  border-color: rgba(255, 255, 255, 0.35);
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-crystal.ribbon-modern.ribbon-left .ribbon-box:before {
  border-color: rgba(255, 255, 255, 0.35);
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-crystal.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box:before {
  border-color: rgba(255, 255, 255, 0.35);
  border-top-color: transparent;
  border-right-color: transparent;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.visibility-hidden {
  visibility: hidden;
}
.visible-ie9 {
  display: none;
}
.ie9 .hidden-ie9 {
  display: none !important;
}
.ie9 .visible-ie9 {
  display: block;
}
.ie9 .visible-ie9-ib {
  display: inline-block;
}
.text-default {
  color: #5c90d2;
}
a.text-default:hover,
a.text-default:active,
a.text-default:focus,
button.text-default:hover,
button.text-default:active,
button.text-default:focus {
  color: #5c90d2;
  opacity: .75;
}
.text-default-dark {
  color: #3e4a59;
}
a.text-default-dark:hover,
a.text-default-dark:active,
a.text-default-dark:focus,
button.text-default-dark:hover,
button.text-default-dark:active,
button.text-default-dark:focus {
  color: #3e4a59;
  opacity: .75;
}
.text-default-darker {
  color: #2c343f;
}
a.text-default-darker:hover,
a.text-default-darker:active,
a.text-default-darker:focus,
button.text-default-darker:hover,
button.text-default-darker:active,
button.text-default-darker:focus {
  color: #2c343f;
  opacity: .75;
}
.text-default-light {
  color: #98b9e3;
}
a.text-default-light:hover,
a.text-default-light:active,
a.text-default-light:focus,
button.text-default-light:hover,
button.text-default-light:active,
button.text-default-light:focus {
  color: #98b9e3;
  opacity: .75;
}
.text-default-lighter {
  color: #ccdcf1;
}
a.text-default-lighter:hover,
a.text-default-lighter:active,
a.text-default-lighter:focus,
button.text-default-lighter:hover,
button.text-default-lighter:active,
button.text-default-lighter:focus {
  color: #ccdcf1;
  opacity: .75;
}
.bg-default {
  background-color: #5c90d2;
}
a.bg-default:hover,
a.bg-default:focus {
  background-color: #3675c5;
}
.bg-default-op {
  background-color: rgba(92, 144, 210, 0.75);
}
a.bg-default-op:hover,
a.bg-default-op:focus {
  background-color: rgba(54, 117, 197, 0.75);
}
.bg-default-dark {
  background-color: #3e4a59;
}
a.bg-default-dark:hover,
a.bg-default-dark:focus {
  background-color: #29313b;
}
.bg-default-dark-op {
  background-color: rgba(62, 74, 89, 0.83);
}
a.bg-default-dark-op:hover,
a.bg-default-dark-op:focus {
  background-color: rgba(41, 49, 59, 0.83);
}
.bg-default-darker {
  background-color: #2c343f;
}
a.bg-default-darker:hover,
a.bg-default-darker:focus {
  background-color: #171b21;
}
.bg-default-light {
  background-color: #98b9e3;
}
a.bg-default-light:hover,
a.bg-default-light:focus {
  background-color: #709ed8;
}
.bg-default-lighter {
  background-color: #ccdcf1;
}
a.bg-default-lighter:hover,
a.bg-default-lighter:focus {
  background-color: #a4c1e6;
}
.text-amethyst {
  color: #a48ad4;
}
a.text-amethyst:hover,
a.text-amethyst:active,
a.text-amethyst:focus,
button.text-amethyst:hover,
button.text-amethyst:active,
button.text-amethyst:focus {
  color: #a48ad4;
  opacity: .75;
}
.text-amethyst-dark {
  color: #4f546b;
}
a.text-amethyst-dark:hover,
a.text-amethyst-dark:active,
a.text-amethyst-dark:focus,
button.text-amethyst-dark:hover,
button.text-amethyst-dark:active,
button.text-amethyst-dark:focus {
  color: #4f546b;
  opacity: .75;
}
.text-amethyst-darker {
  color: #353847;
}
a.text-amethyst-darker:hover,
a.text-amethyst-darker:active,
a.text-amethyst-darker:focus,
button.text-amethyst-darker:hover,
button.text-amethyst-darker:active,
button.text-amethyst-darker:focus {
  color: #353847;
  opacity: .75;
}
.text-amethyst-light {
  color: #c7b7e4;
}
a.text-amethyst-light:hover,
a.text-amethyst-light:active,
a.text-amethyst-light:focus,
button.text-amethyst-light:hover,
button.text-amethyst-light:active,
button.text-amethyst-light:focus {
  color: #c7b7e4;
  opacity: .75;
}
.text-amethyst-lighter {
  color: #e4dcf2;
}
a.text-amethyst-lighter:hover,
a.text-amethyst-lighter:active,
a.text-amethyst-lighter:focus,
button.text-amethyst-lighter:hover,
button.text-amethyst-lighter:active,
button.text-amethyst-lighter:focus {
  color: #e4dcf2;
  opacity: .75;
}
.bg-amethyst {
  background-color: #a48ad4;
}
a.bg-amethyst:hover,
a.bg-amethyst:focus {
  background-color: #8765c6;
}
.bg-amethyst-op {
  background-color: rgba(164, 138, 212, 0.75);
}
a.bg-amethyst-op:hover,
a.bg-amethyst-op:focus {
  background-color: rgba(135, 101, 198, 0.75);
}
.bg-amethyst-dark {
  background-color: #4f546b;
}
a.bg-amethyst-dark:hover,
a.bg-amethyst-dark:focus {
  background-color: #393d4e;
}
.bg-amethyst-dark-op {
  background-color: rgba(79, 84, 107, 0.83);
}
a.bg-amethyst-dark-op:hover,
a.bg-amethyst-dark-op:focus {
  background-color: rgba(57, 61, 78, 0.83);
}
.bg-amethyst-darker {
  background-color: #353847;
}
a.bg-amethyst-darker:hover,
a.bg-amethyst-darker:focus {
  background-color: #1f212a;
}
.bg-amethyst-light {
  background-color: #c7b7e4;
}
a.bg-amethyst-light:hover,
a.bg-amethyst-light:focus {
  background-color: #aa91d7;
}
.bg-amethyst-lighter {
  background-color: #e4dcf2;
}
a.bg-amethyst-lighter:hover,
a.bg-amethyst-lighter:focus {
  background-color: #c7b7e4;
}
.text-city {
  color: #ff6b6b;
}
a.text-city:hover,
a.text-city:active,
a.text-city:focus,
button.text-city:hover,
button.text-city:active,
button.text-city:focus {
  color: #ff6b6b;
  opacity: .75;
}
.text-city-dark {
  color: #555;
}
a.text-city-dark:hover,
a.text-city-dark:active,
a.text-city-dark:focus,
button.text-city-dark:hover,
button.text-city-dark:active,
button.text-city-dark:focus {
  color: #555;
  opacity: .75;
}
.text-city-darker {
  color: #333;
}
a.text-city-darker:hover,
a.text-city-darker:active,
a.text-city-darker:focus,
button.text-city-darker:hover,
button.text-city-darker:active,
button.text-city-darker:focus {
  color: #333;
  opacity: .75;
}
.text-city-light {
  color: #ff8f8f;
}
a.text-city-light:hover,
a.text-city-light:active,
a.text-city-light:focus,
button.text-city-light:hover,
button.text-city-light:active,
button.text-city-light:focus {
  color: #ff8f8f;
  opacity: .75;
}
.text-city-lighter {
  color: #ffb8b8;
}
a.text-city-lighter:hover,
a.text-city-lighter:active,
a.text-city-lighter:focus,
button.text-city-lighter:hover,
button.text-city-lighter:active,
button.text-city-lighter:focus {
  color: #ffb8b8;
  opacity: .75;
}
.bg-city {
  background-color: #ff6b6b;
}
a.bg-city:hover,
a.bg-city:focus {
  background-color: #ff3838;
}
.bg-city-op {
  background-color: rgba(255, 107, 107, 0.75);
}
a.bg-city-op:hover,
a.bg-city-op:focus {
  background-color: rgba(255, 56, 56, 0.75);
}
.bg-city-dark {
  background-color: #555;
}
a.bg-city-dark:hover,
a.bg-city-dark:focus {
  background-color: #3b3b3b;
}
.bg-city-dark-op {
  background-color: rgba(85, 85, 85, 0.83);
}
a.bg-city-dark-op:hover,
a.bg-city-dark-op:focus {
  background-color: rgba(59, 59, 59, 0.83);
}
.bg-city-darker {
  background-color: #333;
}
a.bg-city-darker:hover,
a.bg-city-darker:focus {
  background-color: #1a1a1a;
}
.bg-city-light {
  background-color: #ff8f8f;
}
a.bg-city-light:hover,
a.bg-city-light:focus {
  background-color: #ff5c5c;
}
.bg-city-lighter {
  background-color: #ffb8b8;
}
a.bg-city-lighter:hover,
a.bg-city-lighter:focus {
  background-color: #ff8585;
}
.text-flat {
  color: #44b4a6;
}
a.text-flat:hover,
a.text-flat:active,
a.text-flat:focus,
button.text-flat:hover,
button.text-flat:active,
button.text-flat:focus {
  color: #44b4a6;
  opacity: .75;
}
.text-flat-dark {
  color: #3f5259;
}
a.text-flat-dark:hover,
a.text-flat-dark:active,
a.text-flat-dark:focus,
button.text-flat-dark:hover,
button.text-flat-dark:active,
button.text-flat-dark:focus {
  color: #3f5259;
  opacity: .75;
}
.text-flat-darker {
  color: #242f33;
}
a.text-flat-darker:hover,
a.text-flat-darker:active,
a.text-flat-darker:focus,
button.text-flat-darker:hover,
button.text-flat-darker:active,
button.text-flat-darker:focus {
  color: #242f33;
  opacity: .75;
}
.text-flat-light {
  color: #83d0c7;
}
a.text-flat-light:hover,
a.text-flat-light:active,
a.text-flat-light:focus,
button.text-flat-light:hover,
button.text-flat-light:active,
button.text-flat-light:focus {
  color: #83d0c7;
  opacity: .75;
}
.text-flat-lighter {
  color: #a8ded8;
}
a.text-flat-lighter:hover,
a.text-flat-lighter:active,
a.text-flat-lighter:focus,
button.text-flat-lighter:hover,
button.text-flat-lighter:active,
button.text-flat-lighter:focus {
  color: #a8ded8;
  opacity: .75;
}
.bg-flat {
  background-color: #44b4a6;
}
a.bg-flat:hover,
a.bg-flat:focus {
  background-color: #368f84;
}
.bg-flat-op {
  background-color: rgba(68, 180, 166, 0.75);
}
a.bg-flat-op:hover,
a.bg-flat-op:focus {
  background-color: rgba(54, 143, 132, 0.75);
}
.bg-flat-dark {
  background-color: #3f5259;
}
a.bg-flat-dark:hover,
a.bg-flat-dark:focus {
  background-color: #2a363b;
}
.bg-flat-dark-op {
  background-color: rgba(63, 82, 89, 0.83);
}
a.bg-flat-dark-op:hover,
a.bg-flat-dark-op:focus {
  background-color: rgba(42, 54, 59, 0.83);
}
.bg-flat-darker {
  background-color: #242f33;
}
a.bg-flat-darker:hover,
a.bg-flat-darker:focus {
  background-color: #0f1315;
}
.bg-flat-light {
  background-color: #83d0c7;
}
a.bg-flat-light:hover,
a.bg-flat-light:focus {
  background-color: #5ec2b6;
}
.bg-flat-lighter {
  background-color: #a8ded8;
}
a.bg-flat-lighter:hover,
a.bg-flat-lighter:focus {
  background-color: #83d0c7;
}
.text-modern {
  color: #14adc4;
}
a.text-modern:hover,
a.text-modern:active,
a.text-modern:focus,
button.text-modern:hover,
button.text-modern:active,
button.text-modern:focus {
  color: #14adc4;
  opacity: .75;
}
.text-modern-dark {
  color: #3e4d52;
}
a.text-modern-dark:hover,
a.text-modern-dark:active,
a.text-modern-dark:focus,
button.text-modern-dark:hover,
button.text-modern-dark:active,
button.text-modern-dark:focus {
  color: #3e4d52;
  opacity: .75;
}
.text-modern-darker {
  color: #323e42;
}
a.text-modern-darker:hover,
a.text-modern-darker:active,
a.text-modern-darker:focus,
button.text-modern-darker:hover,
button.text-modern-darker:active,
button.text-modern-darker:focus {
  color: #323e42;
  opacity: .75;
}
.text-modern-light {
  color: #7fe3f2;
}
a.text-modern-light:hover,
a.text-modern-light:active,
a.text-modern-light:focus,
button.text-modern-light:hover,
button.text-modern-light:active,
button.text-modern-light:focus {
  color: #7fe3f2;
  opacity: .75;
}
.text-modern-lighter {
  color: #c4f2f9;
}
a.text-modern-lighter:hover,
a.text-modern-lighter:active,
a.text-modern-lighter:focus,
button.text-modern-lighter:hover,
button.text-modern-lighter:active,
button.text-modern-lighter:focus {
  color: #c4f2f9;
  opacity: .75;
}
.bg-modern {
  background-color: #14adc4;
}
a.bg-modern:hover,
a.bg-modern:focus {
  background-color: #0f8496;
}
.bg-modern-op {
  background-color: rgba(20, 173, 196, 0.75);
}
a.bg-modern-op:hover,
a.bg-modern-op:focus {
  background-color: rgba(15, 132, 150, 0.75);
}
.bg-modern-dark {
  background-color: #3e4d52;
}
a.bg-modern-dark:hover,
a.bg-modern-dark:focus {
  background-color: #283235;
}
.bg-modern-dark-op {
  background-color: rgba(62, 77, 82, 0.83);
}
a.bg-modern-dark-op:hover,
a.bg-modern-dark-op:focus {
  background-color: rgba(40, 50, 53, 0.83);
}
.bg-modern-darker {
  background-color: #323e42;
}
a.bg-modern-darker:hover,
a.bg-modern-darker:focus {
  background-color: #1c2325;
}
.bg-modern-light {
  background-color: #7fe3f2;
}
a.bg-modern-light:hover,
a.bg-modern-light:focus {
  background-color: #51d9ed;
}
.bg-modern-lighter {
  background-color: #c4f2f9;
}
a.bg-modern-lighter:hover,
a.bg-modern-lighter:focus {
  background-color: #96e8f4;
}
.text-smooth {
  color: #ff6c9d;
}
a.text-smooth:hover,
a.text-smooth:active,
a.text-smooth:focus,
button.text-smooth:hover,
button.text-smooth:active,
button.text-smooth:focus {
  color: #ff6c9d;
  opacity: .75;
}
.text-smooth-dark {
  color: #4a5568;
}
a.text-smooth-dark:hover,
a.text-smooth-dark:active,
a.text-smooth-dark:focus,
button.text-smooth-dark:hover,
button.text-smooth-dark:active,
button.text-smooth-dark:focus {
  color: #4a5568;
  opacity: .75;
}
.text-smooth-darker {
  color: #333a47;
}
a.text-smooth-darker:hover,
a.text-smooth-darker:active,
a.text-smooth-darker:focus,
button.text-smooth-darker:hover,
button.text-smooth-darker:active,
button.text-smooth-darker:focus {
  color: #333a47;
  opacity: .75;
}
.text-smooth-light {
  color: #ff90b5;
}
a.text-smooth-light:hover,
a.text-smooth-light:active,
a.text-smooth-light:focus,
button.text-smooth-light:hover,
button.text-smooth-light:active,
button.text-smooth-light:focus {
  color: #ff90b5;
  opacity: .75;
}
.text-smooth-lighter {
  color: #ffb9d0;
}
a.text-smooth-lighter:hover,
a.text-smooth-lighter:active,
a.text-smooth-lighter:focus,
button.text-smooth-lighter:hover,
button.text-smooth-lighter:active,
button.text-smooth-lighter:focus {
  color: #ffb9d0;
  opacity: .75;
}
.bg-smooth {
  background-color: #ff6c9d;
}
a.bg-smooth:hover,
a.bg-smooth:focus {
  background-color: #ff397b;
}
.bg-smooth-op {
  background-color: rgba(255, 108, 157, 0.75);
}
a.bg-smooth-op:hover,
a.bg-smooth-op:focus {
  background-color: rgba(255, 57, 123, 0.75);
}
.bg-smooth-dark {
  background-color: #4a5568;
}
a.bg-smooth-dark:hover,
a.bg-smooth-dark:focus {
  background-color: #353d4a;
}
.bg-smooth-dark-op {
  background-color: rgba(74, 85, 104, 0.83);
}
a.bg-smooth-dark-op:hover,
a.bg-smooth-dark-op:focus {
  background-color: rgba(53, 61, 74, 0.83);
}
.bg-smooth-darker {
  background-color: #333a47;
}
a.bg-smooth-darker:hover,
a.bg-smooth-darker:focus {
  background-color: #1e2229;
}
.bg-smooth-light {
  background-color: #ff90b5;
}
a.bg-smooth-light:hover,
a.bg-smooth-light:focus {
  background-color: #ff5d93;
}
.bg-smooth-lighter {
  background-color: #ffb9d0;
}
a.bg-smooth-lighter:hover,
a.bg-smooth-lighter:focus {
  background-color: #ff86ae;
}
@media screen {
    .print-only {
        display: none !important;
    }
}
@media print {
  #page-container,
  #main-container {
    padding: 0 !important;
  }
  #header-navbar,
  #sidebar,
  #side-overlay,
  #tawkchat-minified-container,#tawkchat-status-middle,
  .no-print,
  .block-options {
    display: none !important;
  }
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
}
.animated.flipOutX,
.animated.flipOutY {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}
@-webkit-keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}
/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}
@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}
@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}
@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}
/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}
@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
@-webkit-keyframes bounceInDown {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInDown {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
@-webkit-keyframes bounceInLeft {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInLeft {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}
@-webkit-keyframes bounceInRight {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInRight {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}
@-webkit-keyframes bounceInUp {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInUp {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}
@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}
@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}
@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}
@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}
@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}
@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}
@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}
@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}
@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}
@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}
@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}
@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}
@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}
@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}
@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}
@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}
@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}
@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}
@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}
/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}
/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@keyframes rollOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}
@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}
@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}
@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}
@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}
@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}
@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}
@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}
@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}
@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}
@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}
@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}
@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}
@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}
@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}
.autocomplete-suggestion b {
  color: #5c90d2;
}
.irs-line {
  height: 5px;
  background: #eee;
  border: none;
  border-radius: 3px;
}
.irs-bar {
  height: 5px;
  border: none;
  background: #5c90d2;
}
.irs-bar-edge {
  height: 5px;
  border: none;
  background: #5c90d2;
  border-radius: 3px 0 0 3px;
}
.irs-slider {
  top: 25px;
  width: 20px;
  height: 20px;
  border: none;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35);
}
.irs-slider:hover {
  background: #fff;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.75);
}
.irs-from,
.irs-to,
.irs-single {
  color: #fff;
  font-size: 13px;
  background: #5c90d2;
}
.irs-grid-pol {
  background: #5c90d2;
}
.jvectormap-tip {
  padding: 6px 8px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #2c343f;
  border: none;
  border-radius: 0;
}
.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  left: 15px;
  padding: 4px;
  line-height: 15px;
  background: #555;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 15px;
  height: 15px;
}
.jvectormap-zoomin:hover,
.jvectormap-zoomout:hover {
  opacity: .75;
}
.jvectormap-zoomout {
  top: 40px;
}
.draggable-column {
  min-height: 100px;
}
.draggable-handler {
  cursor: move;
}
.draggable-placeholder {
  background-color: #f1f1f1;
  border: 1px dashed #ccc;
}
pre.pre-sh {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  border-radius: 0;
}
.dropzone {
  min-height: 200px;
  background-color: #f9f9f9;
  border: 2px dashed #bbb;
  border-radius: 3px;
}
.dropzone .dz-message {
  margin: 65px 0;
  font-size: 16px;
  font-style: italic;
  color: #888;
}
.dropzone:hover {
  background-color: #fcfcfc;
  border-color: #5c90d2;
}
.dropzone:hover .dz-message {
  color: #5c90d2;
}
.datepicker {
  z-index: 1051 !important;
}
.input-daterange .input-group-addon {
  min-width: 30px;
  color: #646464;
  background-color: #f9f9f9;
  border-color: #e6e6e6;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #faeab9;
  border-color: #faeab9;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #5c90d2;
  border-color: #5c90d2;
}
.colorpicker.dropdown-menu {
  min-width: 130px;
}
div.tagsinput {
  padding: 6px 12px 1px;
  border-color: #e6e6e6;
  border-radius: 3px;
}
div.tagsinput input {
  padding-top: 0;
  padding-bottom: 0;
  height: 22px;
}
.form-material div.tagsinput {
  padding-right: 0;
  padding-left: 0;
  border: none;
  border-bottom: 1px solid #e6e6e6;
}
div.tagsinput span.tag {
  padding: 2px 5px;
  height: 22px;
  line-height: 18px;
  color: #fff;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  background-color: #5c90d2;
  border: none;
}
div.tagsinput span.tag a {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
}
div.tagsinput span.tag a:hover {
  color: rgba(255, 255, 255, 0.75);
}
.select2-container .select2-selection--single {
  height: 34px;
}
.select2-container .select2-dropdown {
  border-color: #e6e6e6;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.select2-container--default .select2-selection--single {
  border-color: #e6e6e6;
  border-radius: 3px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 12px;
  line-height: 34px;
}
.form-material .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 34px;
}
.form-material .select2-container--default .select2-selection--single {
  border: none;
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #aaa;
}
.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #e6e6e6;
  border-radius: 3px;
}
.form-material .select2-container--default .select2-selection--multiple,
.form-material .select2-container--default.select2-container--focus .select2-selection--multiple {
  border: none;
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered,
.select2-container--default.select2-container--focus .select2-selection--multiple .select2-selection__rendered {
  padding-right: 12px;
  padding-left: 12px;
}
.form-material .select2-container--default .select2-selection--multiple .select2-selection__rendered,
.form-material .select2-container--default.select2-container--focus .select2-selection--multiple .select2-selection__rendered {
  padding-left: 0;
}
.has-error .select2-container--default .select2-selection--single,
.has-error .select2-container--default .select2-selection--multiple {
  border-color: #d26a5c;
}
.has-error .select2-container--default.select2-container--focus .select2-selection--single,
.has-error .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #c54736;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  height: 22px;
  line-height: 22px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  background-color: #5c90d2;
  border: none;
  border-radius: 3px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.5);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: rgba(255, 255, 255, 0.75);
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #e6e6e6;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5c90d2;
}
.select2-container--default .select2-search--inline .select2-search__field {
  padding-right: 0;
  padding-left: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-material .select2-container--default .select2-search--inline .select2-search__field {
  padding-left: 0;
}
.select2-search--dropdown .select2-search__field {
  padding: 6px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.slick-slider {
  margin-bottom: 45px;
}
.slick-slider.slick-padding-dots {
  padding-bottom: 45px;
  margin-bottom: 0;
}
.slick-slider.slick-padding-dots .slick-dots {
  bottom: 0;
}
.slick-slider.slick-nav-hover .slick-prev,
.slick-slider.slick-nav-hover .slick-next {
  opacity: 0;
  -webkit-transition: opacity 0.25s ease-out;
  transition: opacity 0.25s ease-out;
}
.slick-slider.slick-nav-hover:hover .slick-prev,
.slick-slider.slick-nav-hover:hover .slick-next {
  opacity: .25;
}
.slick-slider.slick-nav-hover:hover .slick-prev:hover,
.slick-slider.slick-nav-hover:hover .slick-next:hover {
  opacity: .6;
}
.slick-slider.slick-nav-white .slick-prev,
.slick-slider.slick-nav-white .slick-next {
  background-color: #fff;
  opacity: .4;
}
.slick-slider.slick-nav-white .slick-prev:hover,
.slick-slider.slick-nav-white .slick-next:hover {
  background-color: #fff;
  opacity: .8;
}
.slick-slider.slick-nav-white .slick-prev:before,
.slick-slider.slick-nav-white .slick-next:before {
  color: #000;
}
.slick-slider .slick-dots {
  bottom: -45px;
}
.slick-slider .slick-prev,
.slick-slider .slick-next {
  margin-top: -5px;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #000;
  opacity: .25;
  z-index: 10;
}
.slick-slider .slick-prev:hover,
.slick-slider .slick-next:hover {
  background-color: #000;
  opacity: .6;
}
.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
  font-family: 'FontAwesome';
  font-size: 14px;
  line-height: 28px;
}
.slick-slider .slick-prev {
  left: 10px;
}
.slick-slider .slick-prev:before {
  content: "\f060";
}
.slick-slider .slick-next {
  right: 10px;
}
.slick-slider .slick-next:before {
  content: "\f061";
}
.note-editor.note-frame {
  border-color: #ddd;
}
.note-editor .note-toolbar {
  border-bottom-color: #ddd;
}
.note-editor .note-statusbar .note-resizebar {
  border-top-color: #ddd;
}
.note-editor .note-toolbar.btn-toolbar {
  margin-left: 0;
}
.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: none !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.pie-chart {
  position: relative;
  display: inline-block;
}
.pie-chart > span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: -2px;
  right: 0;
  left: 0;
  text-align: center;
}
.flot-tooltip {
  position: absolute;
  display: none;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  padding: 4px 8px;
}
.flot-pie-label {
  font-size: 13px;
  text-align: center;
  padding: 4px 8px;
  color: #fff;
}
.legend > table td {
  padding: 3px 4px;
  font-size: 14px;
}
.fc-event {
  padding-left: 3px;
  padding-right: 3px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.75);
  border: 1px solid #b5d0eb;
  background-color: #b5d0eb;
  border-radius: 0;
}
.fc-event:hover {
  color: rgba(0, 0, 0, 0.75);
}
.fc button {
  height: 34px;
  line-height: 34px;
  font-weight: 600;
}
.fc-state-default.fc-corner-left {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.fc-state-default.fc-corner-right {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.fc-state-default {
  color: #393939;
  background-color: #f3f3f3;
  background-image: none;
  border: 1px solid #e6e6e6;
  text-shadow: none;
  box-shadow: none;
}
.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #393939;
  background-color: #dfdfdf;
  border-color: #c7c7c7;
}
.fc-state-hover {
  color: #393939;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.fc-state-down,
.fc-state-active {
  background-color: #c5c5c5;
  border-color: #aeaeae;
  box-shadow: none;
}
.fc-state-disabled {
  opacity: .35;
}
.fc-toolbar {
  margin-bottom: 29px;
}
.fc-toolbar h2 {
  font-weight: 400;
}
.fc thead th.fc-widget-header {
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #f9f9f9;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed hr,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #eee;
}
.placeholder {
  color: #aaa;
}
;